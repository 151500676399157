import { initialOrderFree, initialOrderItemRecord, ORDER_FREE_EVENTS, TOrderFreeActions, TOrderFreeReducer } from './d';
import { useCallback, useMemo, useReducer, useRef } from 'react';
import OrderFreeContext from './context';


const reducer = (state: TOrderFreeReducer, action: TOrderFreeActions) => {
    switch(action.type) {
        default:
            return state;

        case ORDER_FREE_EVENTS.SET_IS_FROM_STORAGE: {
            return {
                ...state,
                isFromStorage: action.payload
            };
        }
            
        case ORDER_FREE_EVENTS.ADD_NEW_ITEM: {
            return {
                ...state,
                items: [
                    ...state.items,
                    {
                        ...initialOrderItemRecord,
                        id: action.payload
                    }
                ]
            };
        }
        case ORDER_FREE_EVENTS.REMOVE_ITEM: {
            const index = state.items.findIndex((x: any) => x.id === action.payload);
            if(index === -1) return state;
            const array  = [...state.items];
            array.splice(index, 1);
            return {
                ...state,
                items: [...array]
            };
        }

        case ORDER_FREE_EVENTS.SET_DATA: {
            return {
                ...state,
                ...action.payload
            };
        }

        case ORDER_FREE_EVENTS.SAVE_DATA: {
            localStorage.setItem('ORDER_STATE_ITEMS', JSON.stringify(state));
            return  state;
        }

        case ORDER_FREE_EVENTS.RESET_STATE: {
            return {
                items: [
                    {
                        id: '1720895846915',
                        ...initialOrderItemRecord
                    },
                ] as any
            };
        }
    }
};


export const useOrderFreeContext = (entryData = {}) => {
    const dataReduceState = useRef({
        ...initialOrderFree,
        ...entryData
    });

    const [state, dispatch] = useReducer(reducer, dataReduceState.current as TOrderFreeReducer);
    
    const addItem = useCallback((payload: number) => dispatch({
        type: ORDER_FREE_EVENTS.ADD_NEW_ITEM,
        payload
    }),[dispatch]);

    const removeItem = useCallback((id: string) => dispatch({
        type: ORDER_FREE_EVENTS.REMOVE_ITEM,
        payload: id
    }),[dispatch]);

    const resetState = useCallback(() => dispatch({
        type: ORDER_FREE_EVENTS.RESET_STATE
    }),[dispatch]);

    const saveData = useCallback(() => dispatch({
        type: ORDER_FREE_EVENTS.SAVE_DATA
    }),[dispatch]);

    const setData = useCallback(() => dispatch({
        type: ORDER_FREE_EVENTS.SET_DATA
    }),[dispatch]);

    const setFromStorage = useCallback((payload: boolean) => dispatch({
        type: ORDER_FREE_EVENTS.SET_IS_FROM_STORAGE,
        payload
    }),[dispatch]);

    return useMemo(()=> {
        return {
            ...state,
            addItem,
            removeItem,
            resetState,
            saveData,
            setData,
            setFromStorage
        };
    },[state, addItem, removeItem, resetState, saveData, setData, setFromStorage]);
};

export type TOrderFreeContextType = ReturnType<typeof useOrderFreeContext>;

const OrderFreeContextContainer =  ({
                                        children
                                    }: any) => {
    
    const providerData = useOrderFreeContext();
    
    
    return (
        <OrderFreeContext.Provider value={providerData}>
            {children}
        </OrderFreeContext.Provider>
    );
};

export default OrderFreeContextContainer;
