import { PreviewShiftBody, PreviewShiftContainer, PreviewShiftHeader, PreviewShiftHeaderText } from './style';
import { useMemo } from 'react';
import { Translate } from '../../../../../internationalization/translate';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import useGetShiftSchedulingByDateTypeQuery
    from '../../../../../core/api/hooks/query/shiftScheduling/useGetShiftSchedulingByDateTypeQuery';
import PreviewEndedShift from './components/PreviewEndedShift';
import { add, round, subtract } from 'lodash';


const PreviewShiftModal = ({ shiftType, shiftDate }: any) => {
    const { t } = useTranslation();
    const headerText = useMemo(() => {
        const tKey = (() => {
            if (shiftType === 1) return Translate.schedulingFirstShift;
            if (shiftType === 2) return Translate.schedulingSecondShift;
            return Translate.schedulingThirdShift;
        })();
        return `${t(tKey)}`;
    }, [t, shiftType]);
    const { data } = useGetShiftSchedulingByDateTypeQuery({
        shiftDate,
        shiftType,
        isEnabled: !!shiftDate && !!shiftType
    });

    const [isEnded, dataEndPreview] = useMemo(() => [
        1,//!!data?.length && data.every((x: any) => !!x.status && (x.status === SHIFT_SCHEDULING_STATUS.END || x.status === SHIFT_SCHEDULING_STATUS.DISP_END)),
        (data || []).map((x: any) => ({
            id: x.id,
            driver: `${x.driver?.firstName} ${x.driver?.lastName}`,
            status: x.status,
            shiftDate,
            shiftType,
            shiftStartAmount: x.shiftStartAmount,
            shiftExpenses: x.shiftExpenses,
            shiftEarnings: x.shiftEarnings,
            shiftEndAmount: round(subtract(add(Number(x.shiftStartAmount) || 0, Number(x.shiftEarnings) || 0), (Number(x.shiftExpenses) || 0)), 2),
            shiftSchedulingExpenses: x?.shiftSchedulingExpenses || [],
            orders: x?.orders || []
        }))
    ], [data, shiftDate, shiftType]);

    return (
        <PreviewShiftContainer isEnded={Number(isEnded)}>
            <PreviewShiftHeader>
                <PreviewShiftHeaderText>{headerText}</PreviewShiftHeaderText>
                <PreviewShiftHeaderText>{moment(shiftDate).format('DD.MM.YYYY')}</PreviewShiftHeaderText>
            </PreviewShiftHeader>
            <PreviewShiftBody>
                {/*<ConditionalRendering condition={!isEnded}>
                    <PreviewCurrentShift dataPreview={dataPreview} />
                </ConditionalRendering>
                <ConditionalRendering condition={isEnded}>*/}
                <PreviewEndedShift dataPreview={dataEndPreview} />
                {/* </ConditionalRendering>*/}
            </PreviewShiftBody>
        </PreviewShiftContainer>
    );
};

export default PreviewShiftModal;