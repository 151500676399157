import React, { useCallback, useContext, useMemo } from 'react';
import {
    UpdateOrderInformationButton,
    UpdateOrderInformationContainer,
    UpdateOrderInformationFooter,
    UpdateOrderInformationForm
} from './style';
import useModalFormData from '../../../../../hooks/useModalFormData';
import { useTranslation } from 'react-i18next';
import { Translate } from 'internationalization/translate';
import UiDataContext from '../../../../../context/UiContext/context';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import * as yup from 'yup';
import { Formik } from 'formik';
import FormikTextField from '../../../../../components/Formik/FormikTextField';
import AddressSearch from '../../../../../components/Autocomplete/AddressSearch';
import Translation from '../../../../../components/Translation';
import { queryClient } from '../../../../../core/root/ReactQueryProvider';
import useOrderUpdateInformationMutation
    from '../../../../../core/api/hooks/mutations/order/useOrderUpdateInformationMutation';
import FormikSelectField from '../../../../../components/Formik/FormikSelectField';
import useGetCurrentDriversQuery from '../../../../../core/api/hooks/query/orders/useGetCurrentDriversQuery';


const schema = yup.object().shape({
    customerPhone: yup.string().required(Translate.required),
    address: yup.string().required(Translate.required)
});
const UpdateOrderInformation = ({ id, closeModal, order }: any) => {

    const { setLoading, resetLoading } = useContext(UiDataContext);
    const { t } = useTranslation();
    const updateOrderInformationMutation = useOrderUpdateInformationMutation(id);

    const { data: listState } = useGetCurrentDriversQuery({ active: true });

    const options = useMemo(() => (listState || []).map((x: any) => ({
        label: `${x?.driver?.firstName} ${x?.driver?.lastName}`,
        value: `${x.driverId}`
    })), [listState]);

    const onSubmit = useCallback(async (values: any) => {
        if (!values || !id) return;
        try {
            setLoading();
            const result = await updateOrderInformationMutation.mutateAsync({
                data: {
                    customerName: values?.customerName,
                    customerPhone: values.customerPhone,
                    customerBuildingNumber: values?.customerBuildingNumber,
                    customerAddress: values?.address,
                    customerAddressJson: values?.addressObject,
                    driverId: values?.driverId
                }
            });
            if (result?.status === 200) {
                successToast(t(result.message));
                await queryClient.invalidateQueries({ queryKey: ['get-orders'] });
                closeModal();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
    }, [id, setLoading, resetLoading, updateOrderInformationMutation]);

    const handleCancel = useCallback(() => {
        closeModal();
    }, [closeModal]);


    useModalFormData({
        modalTitle: t(Translate.orderViewUpdateInformationTitle, { dailyNumber: order.dailyNumber })
    });

    return (
        <UpdateOrderInformationContainer>
            <Formik
                initialValues={{
                    customerName: order?.customerName || '',
                    customerPhone: order?.customerPhone || '',
                    address: order?.customerAddress || '',
                    addressObject: order?.customerAddressJson,
                    customerBuildingNumber: order?.customerBuildingNumber || '',
                    driverId: order?.driverId
                }}
                validationSchema={schema}
                onSubmit={onSubmit}
            >
                <UpdateOrderInformationForm>
                    {/* <UpdateOrderInformationRow>*/}
                    <FormikTextField
                        name={'customerName'}
                        variant={'outlined'}
                        fullWidth
                        label={Translate.defineOrderCustomerNameLabel}
                    />
                    <FormikTextField
                        name={'customerPhone'}
                        variant={'outlined'}
                        fullWidth
                        label={Translate.defineOrderCustomerPhoneLabel}
                    />
                    <FormikTextField
                        name={'customerBuildingNumber'}
                        variant={'outlined'}
                        fullWidth
                        label={Translate.defineOrderCustomerBuildingNumberLabel}
                    />
                    <FormikSelectField
                        name={'driverId'}
                        variant={'outlined'}
                        fullWidth
                        label={Translate.defineOrderFooterDriver}
                        options={options}
                    />
                    <AddressSearch isFormik name={'address'} showAddress showSwitch fullWidth />
                    {/* </UpdateOrderInformationRow>*/}
                    <UpdateOrderInformationFooter>
                        <UpdateOrderInformationButton isWhite onClick={handleCancel}>
                            <Translation use={Translate.cancel} />
                        </UpdateOrderInformationButton>
                        <UpdateOrderInformationButton type="submit">
                            <Translation use={Translate.save} />
                        </UpdateOrderInformationButton>
                    </UpdateOrderInformationFooter>
                </UpdateOrderInformationForm>
            </Formik>
        </UpdateOrderInformationContainer>
    );
};

export default UpdateOrderInformation;