import React, { useCallback, useContext, useMemo } from 'react';
import {
    OrderViewListHeaderContainer,
    OrderViewListHeaderDriver,
    OrderViewListHeaderSearch,
    OrderViewListHeaderTitle
} from './style';
import Translation from 'components/Translation';
import OrderViewContext from '../context/context';
import { Translate } from 'internationalization/translate';
import useGetCurrentDriversQuery from '../../../../../../core/api/hooks/query/orders/useGetCurrentDriversQuery';



const OrderViewListHeader = () => {
    
    const {filter, setFilter, clearFilter} = useContext(OrderViewContext);
    
    
    const handleSearch = useCallback((value: string)=> {
        if(!value) return;
        setFilter({
            field: 'search',
            data: value
        });
    },[setFilter]);

    const handleDriver = useCallback((e: any)=> {
        setFilter({
            field: 'driverId',
            data: e.target.value
        });
    },[setFilter]);

    const handlerClearDriver = useCallback(()=> clearFilter('driverId'),[clearFilter]);
    
    const handleClear = useCallback(()=> clearFilter('search'),[clearFilter]);

    const  { data: listState } = useGetCurrentDriversQuery({ active: true });

    const options = useMemo(() => (listState || []).map((x: any)=> ({
        label: `${x?.driver?.firstName} ${x?.driver?.lastName}`,
        value: `${x.driverId}`
    })),[listState]);
    
    return (
        <OrderViewListHeaderContainer>
            <Translation use={Translate.orderViewTitle} as={OrderViewListHeaderTitle} />
            <OrderViewListHeaderDriver
                value={filter?.driverId || ''}
                options={options}
                onChange={handleDriver}
                onClear={handlerClearDriver}
                label={'driver'}
                variant={'outlined'}
                useHelperText={false}
            />
            <OrderViewListHeaderSearch 
                handlerSearch={handleSearch}
                clearSearch={handleClear}
                placeholder={'search'}
            />
        </OrderViewListHeaderContainer>
    );
};

export default OrderViewListHeader;