import {
    SchedulingBoardViewShiftAction,
    SchedulingBoardViewShiftBigPart,
    SchedulingBoardViewShiftContainer,
    SchedulingBoardViewShiftEndedData,
    SchedulingBoardViewShiftFristRow,
    SchedulingBoardViewShiftText
} from './style';
import { useEffect, useMemo } from 'react';
import ConditionalRendering from '../../../../../../../components/Utils/ConditionalRendering';
import ButtonIcon from '../../../../../../../components/button/ButtonIcon';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { SHIFT_SCHEDULING_STATUS, SHIFT_SCHEDULING_TABLE_EVENTS } from '../../../d';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { selectSettingsShifts } from '../../../../../../../store/app/appSlice';
import { add, round, subtract } from 'lodash';
import { formatPriceSymbol } from '../../../../../../../utils';
import SearchIcon from '@mui/icons-material/Search';
import { Translate } from '../../../../../../../internationalization/translate';


const SchedulingBoardViewShift = ({ row, shiftType, isDisabled }: any) => {
    const settingsShift = useSelector(selectSettingsShifts);
    const [status, isAdded, textToShow, isShiftStarted, isEnded, isCurrentShiftStarted, shiftStartAmount, shiftEarnings, shiftExpenses, shiftDiff] = useMemo(() => {
        const data = row?.[`${shiftType}`];
        const shift = settingsShift?.[`${shiftType}`];
        const shiftEarnings = round(data?.reduce((acc: number, x: any) => add(acc, Number(x.shiftEarnings || 0)), 0), 2);
        const shiftStartAmount = round(data?.reduce((acc: number, x: any) => add(acc, Number(x.shiftStartAmount || 0)), 0), 2);
        const shiftExpenses = round(data?.reduce((acc: number, x: any) => add(acc, Number(x?.shiftExpenses || 0)), 0), 2);
        const isEnded = !!data?.length && data.every((x: any) => !!x.status && (x.status === SHIFT_SCHEDULING_STATUS.END || x.status === SHIFT_SCHEDULING_STATUS.DISP_END));
        const isStarted = !!data?.length && data.some((x: any) => !!x.status && moment(`${x.shiftDate} ${x.shiftStartTime}`).isBefore(new Date()));
        const isFuture = !!data?.length && data.some((x: any) => moment(`${x.shiftDate} ${x.shiftStartTime}`).isAfter(new Date()));
        const isDriverStarts = !!data?.length && data.some((x: any) => x.status === 1);
        const isCurrentDate = moment(new Date()).tz('Europe/Belgrade').format('YYYY-MM-DD') === row.date;
        const time = moment().tz('Europe/Belgrade').hours();
        const shiftFirstToTime = +shift?.to.substring(0,2);
        const currentShift = (+time >= 6 && +time <= +shiftFirstToTime) ? 1 : 2;
        const isCurrentShiftStarted = moment(`${row.date} ${shift?.from}`, 'YYYY-MM-DD HH:mm').subtract(1, 'hour').isBefore(moment());
        const isDriverWithStatusEnd = !!data?.length && data.some((x: any) => x.status === 2);
        return [
            ((isStarted && !isEnded) || isDriverStarts) ? SHIFT_SCHEDULING_STATUS.START : (isEnded && isDriverWithStatusEnd) ? 4 : isEnded ? SHIFT_SCHEDULING_STATUS.END : isFuture ? 0 : -1,
            !!data?.length,
            (() => {
                if (!data) return;
                const arr = data.map((x: any) => `${x?.driver?.firstName} ${x?.driver?.lastName}`);
                return arr.join(', ');
            })(),
            (row?.date && shift?.from && moment(`${row?.date} ${shift?.from}`).toDate().getTime() <= moment().toDate().getTime()) || isDriverStarts,
            isEnded,
            isCurrentDate && !isCurrentShiftStarted && +shiftType === currentShift,
            formatPriceSymbol(shiftStartAmount),
            formatPriceSymbol(shiftEarnings),
            formatPriceSymbol(shiftExpenses),
            formatPriceSymbol(round(subtract(add(shiftEarnings || 0, shiftStartAmount || 0), shiftExpenses || 0), 2))
        ];
    }, [row, shiftType, settingsShift]);

    useEffect(() => {
        console.log('started ', isCurrentShiftStarted, isDisabled, isEnded, isAdded);
        console.log('shift ', { shiftDate: row?.date, shiftType });
    });

    return (
        <SchedulingBoardViewShiftContainer status={status}>
            <SchedulingBoardViewShiftText>
                {isAdded && isEnded ? (<SchedulingBoardViewShiftEndedData>
                    <SchedulingBoardViewShiftFristRow>
                        <SchedulingBoardViewShiftBigPart>Početak: {shiftStartAmount}</SchedulingBoardViewShiftBigPart>
                        <SchedulingBoardViewShiftBigPart>Trošak: {shiftExpenses}</SchedulingBoardViewShiftBigPart>
                    </SchedulingBoardViewShiftFristRow>
                    <SchedulingBoardViewShiftFristRow>
                        <SchedulingBoardViewShiftBigPart>Pazar: {shiftEarnings}</SchedulingBoardViewShiftBigPart>
                        <SchedulingBoardViewShiftBigPart>Razlika: {shiftDiff}</SchedulingBoardViewShiftBigPart>
                    </SchedulingBoardViewShiftFristRow>
                </SchedulingBoardViewShiftEndedData>) : !isAdded ? null : <p> {textToShow}</p>}
            </SchedulingBoardViewShiftText>
            <SchedulingBoardViewShiftAction>
                <ConditionalRendering condition={isAdded && (isShiftStarted || isEnded)}>
                    <ButtonIcon
                        title={Translate.preview}
                        data-action={SHIFT_SCHEDULING_TABLE_EVENTS.PREVIEW_SHIFT}
                        data-action-param={JSON.stringify({ shiftDate: row?.date, shiftType })}
                    >
                        <SearchIcon />
                    </ButtonIcon>
                </ConditionalRendering>
                <ConditionalRendering condition={isEnded || !isAdded}>
                    <ButtonIcon disabled>
                        <></>
                    </ButtonIcon>
                </ConditionalRendering>
                <ConditionalRendering condition={!isEnded && (isAdded || isCurrentShiftStarted) &&  !isDisabled}>
                    <ButtonIcon
                        data-action={SHIFT_SCHEDULING_TABLE_EVENTS.EDIT_SHIFT}
                        data-action-param={JSON.stringify({ shiftDate: row?.date, shiftType })}
                        title={Translate.editText}
                    >
                        <EditIcon />
                    </ButtonIcon>
                </ConditionalRendering>
                <ConditionalRendering condition={!isEnded && !isAdded && !isShiftStarted && !isDisabled}>
                    <ButtonIcon
                        title={Translate.add}
                        data-action={SHIFT_SCHEDULING_TABLE_EVENTS.ADD_NEW_SHIFT_ITEM}
                        data-action-param={JSON.stringify({ shiftDate: row?.date, shiftType })}
                    >
                        <AddIcon />
                    </ButtonIcon>
                </ConditionalRendering>
            </SchedulingBoardViewShiftAction>
        </SchedulingBoardViewShiftContainer>
    );
};


export default SchedulingBoardViewShift;