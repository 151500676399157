import styled, { css } from 'styled-components';
import { Flex } from '../../../../../../assets/style';
import { Typography } from '@mui/material';
import Button from '../../../../../../components/button';
import ButtonIcon from '../../../../../../components/button/ButtonIcon';
import { Colors } from '../../../../../../theme/palette';

export const EmptyOrderFreeContentContainer = styled(Flex)`
    flex: 2;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
`;


export const EmptyOrderFreeContentText = styled(Typography).attrs({
    variant: 'h4'
})`
    color: #FFF;
    text-align: center;
    font: normal 400 40px/normal 'Readex Pro';
`;

export const DefineOrderFreeItemsContentContainer = styled(Flex)`
    flex: 2;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
`;

export const DefineOrderFreeItemsContent = styled(Flex)`
    width: 100%;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 25px;
    padding: 0 0 15px;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: calc(100vw - 250px);
`;

export const DefineOrderFreeItemsFooter = styled(Flex)`
    justify-content: center;
    align-items: center;
    gap: 200px;
    min-height: 40px;
    padding: 5px 0 5px;
    position: relative;
`;

export const DefineOrderFreeButton = styled(Button)<{isChanged?: number}>`
  &&& {   
    min-width: 200px;
    max-width: 200px;
    font-size: 16px;
    line-height: 20px;
    padding: 15px 0;
      
      ${props => props.isChanged && css`
        background: ${Colors.red};
        color: ${Colors.white};  
      `}
  }
`;

export const DefineOrderRemoveButton = styled(ButtonIcon)`
  &&& {  
      position: absolute;
      left: 0;
      color: ${Colors.black};
      background: ${Colors.white};
  }
`;