export enum NotificationType {
    ORDER_FINISHED = 0x1,
    ORDER_ITEM_FINISHED = 0x2,
    ORDER_ABORTED = 0x3,
    ORDER_CUSTOMER_NOT_SHOW = 0x4,
    ORDER_SAW = 0x5,
    ORDER_CUSTOMER_NOT_SHOW_FINISH = 0x6,
}

export enum MessageType {
    SHIFT_STATUS_START = 'SHIFT_STATUS_START',
    SHIFT_STATUS_STOP = 'SHIFT_STATUS_STOP',
    SHIFT_STATUS_PAUSE = 'SHIFT_STATUS_PAUSE',
    SHIFT_STATUS_RESUME = 'SHIFT_STATUS_RESUME',

    DRIVER_ORDER_SAW = 'DRIVER_ORDER_SAW',

    DRIVER_LOCATION = 'DRIVER_LOCATION'
}