import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    OrderDriverItemAssigned,
    OrderDriverItemColumn,
    OrderDriverItemContainer,
    OrderDriverItemRightActionButton,
    OrderDriverItemRow,
    OrderDriverOffIcon,
    OrderDriverOfflineIcon
} from './style';
import { formatPriceSymbol } from '../../../../../../utils';
import { Translate } from '../../../../../../internationalization/translate';
import Translation from '../../../../../../components/Translation';
import OrderViewAssignContext from '../../context/context';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { OrderDriverViewActionsListEvents } from '../../OrdersViewPart/d';
import { useTranslation } from 'react-i18next';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { useSelector } from 'react-redux';
import { selectMapState, selectShiftDriverPause } from '../../../../../../store/app/appSlice';
import moment from 'moment';

const OrderDriverItem = ({ driver }: any) => {
    const { t } = useTranslation();
    const { setDriver, selectedOrders, selectedDriver } = useContext(OrderViewAssignContext);
    const driverPause = useSelector(selectShiftDriverPause(driver?.driver?.id));
    const mapState = useSelector(selectMapState);
    const [indicator, setIndicator] = useState<boolean>(false);
    const time = useRef<null | NodeJS.Timer>(null);
    const [isAssignable, isVisible, id, dataDriver] = useMemo(() => [
        selectedOrders && selectedOrders?.length > 0,
        selectedDriver ? 1 : 0,
        driver?.driver?.id,
        driver?.driver,
    ], [selectedOrders, selectedDriver, driver]);

    const selectDriver = useCallback(() => {
        if (!driver?.driver?.id) return;
        setDriver(driver?.driver?.id);
    }, [setDriver, driver]);

    const checkState = useCallback(() => {
        setIndicator(!mapState || !mapState?.[driver?.driver?.id] || moment().diff(moment(mapState?.[driver?.driver?.id]?.lastTimeActive), 'seconds') >= 25);
    }, [mapState, driver, setIndicator]);


    useEffect(() => {
        time.current = setInterval(checkState, 25000);
        return () => {
            if (time.current) {
                clearInterval(time.current);
            }
        };
    }, []);


    useEffect(() => {
        if(time.current) clearInterval(time.current);
        time.current = setInterval(checkState, 25000);
        checkState();
    }, [mapState]);

    return (
        <OrderDriverItemContainer selected={Number(selectedDriver === driver?.driver?.id)} status={driver.status} onDuty={+driver.driverOnDuty}
                                  free={driver?.currentOrders && driver?.isFirstCompleted ? 1 : 0} pulse={+driverPause}>
            <OrderDriverOfflineIcon title={indicator ? 'Offline' : 'Online'}> {indicator ? <OrderDriverOffIcon /> :
                <GpsFixedIcon />} </OrderDriverOfflineIcon>
            <OrderDriverItemColumn flex={2} onClick={selectDriver}>
                <OrderDriverItemRow>
                    <div>{`#${driver?.driver?.id || ''} ${driver?.driver?.firstName || ''} ${driver?.driver?.lastName || ''}`}</div>
                    <div>{`#${driver?.vehicle?.vehicleNumber} ${(driver?.vehicle?.vehicleTypeName || '').toLowerCase()}`}</div>
                </OrderDriverItemRow>
                <OrderDriverItemRow>
                    <Translation use={Translate.orderDriverItemViewCurrentAmount}
                                 params={{ amount: formatPriceSymbol(driver?.ordersAmount) }} />
                    <Translation use={Translate.orderDriverItemViewCurrentCompleted}
                                 params={{ count: driver?.completedOrders }} />
                </OrderDriverItemRow>
                <OrderDriverItemRow>
                    <Translation use={Translate.orderDriverItemViewCurrentOrders}
                                 params={{ currentOrders: driver?.currentOrders }} />
                    <Translation use={Translate.orderDriverItemViewCurrentBided} params={{ currentBided: '' }} />
                </OrderDriverItemRow>
            </OrderDriverItemColumn>
            <OrderDriverItemAssigned>
                <OrderDriverItemRightActionButton
                    title={t(Translate.orderDriverListActionAssigned)}
                    data-action={OrderDriverViewActionsListEvents.ASSIGNED_DRIVER}
                    data-action-id={id}
                    data-action-param={JSON.stringify(dataDriver)}
                    disabled={!isAssignable}
                    visibility={isVisible}
                >
                    <DirectionsCarIcon />
                </OrderDriverItemRightActionButton>
            </OrderDriverItemAssigned>
        </OrderDriverItemContainer>
    );
};

export default OrderDriverItem;