import React, { FC, useCallback, useContext } from 'react';
import { MakeActiveContainer, MakeActiveText } from './style';
import useModalFormData from '../../../../../hooks/useModalFormData';
import { Translate } from '../../../../../internationalization/translate';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { queryClient } from '../../../../../core/root/ReactQueryProvider';
import UiDataContext from '../../../../../context/UiContext/context';
import { useTranslation } from 'react-i18next';
import Translation from '../../../../../components/Translation';
import useMakeActiveOrderMutation from '../../../../../core/api/hooks/mutations/order/useMakeActiveOrderMutation';

type Props = {
    id: number|string
    closeModal: () => void,
    order: any
}

const OrderMakeActive: FC<Props> = ({
                                          id,
                                          closeModal,
                                          order
                                      }) => {

    const { setLoading, resetLoading } = useContext(UiDataContext);
    const {t} = useTranslation();
    const makeActiveOrderMutation = useMakeActiveOrderMutation();

    const handlerOnSubmit = useCallback(async () => {
        if (!id) return false;
        try {
            setLoading();
            const result = await makeActiveOrderMutation.mutateAsync({
                id
            });
            if (result?.status === 200) {
                successToast(t(result.message));
                await queryClient.invalidateQueries({queryKey: ['get-orders']});
                await queryClient.invalidateQueries({queryKey: ['get-current-drivers']});
                closeModal();
            }
        }catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
            return false;
        }finally {
            resetLoading();
        }
        return true;
    },[id, setLoading, resetLoading, makeActiveOrderMutation]);

    useModalFormData({
        modalTitle: t(Translate.orderMakeActiveTitle, {dailyNumber: order?.dailyNumber}),
        handlerOnSubmit
    });

    return (
        <MakeActiveContainer>
            <Translation use={'orderMakeActiveText'} as={MakeActiveText}/>
        </MakeActiveContainer>
    );
};

export default OrderMakeActive;