import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextFieldContainer } from 'components/input/style';
import ClearIcon from '@mui/icons-material/Clear';
import {
    InputAdornment,
    Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';


const InputCurrency = ({
                           fullWidth,
                           label,
                           icon,
                           iconRight,
                           value,
                           onChange,
                           variant = 'standard',
                           size = 'small',
                           error,
                           helperText,
                           useHelperText = true,
                           readOnly = false,
                           // eslint-disable-next-line @typescript-eslint/no-unused-vars
                           loading = false,
                           textAlign = 'left',
                           selectOnFocus,
                           onFocus,
                           inputRef,
                           placeholder,
                           isClearable,
                           onClear,
                           onBlur,
                           maxLength,
                           thousandDelimiter = '.',
                           decimalDelimiter = ',',
                           decimalPlaces = 2,
                           isDark = true,
                           ...rest
                       }: any) => {

    const [selectionOnFocus, setSelectionOnFocus] = useState<boolean>(false);
    const { t } = useTranslation();

    const formatValue = (value: string) => {

        const { array, decimal } = value.split('').reduce((acc: any, x: string) => {
            if (/\d/.exec(x)) {
                acc.array.push(x);
                return acc;
            }
            if (x === decimalDelimiter && decimalPlaces > 0) {
                acc.decimal = acc.array.length;
            }
            return acc;
        }, {
            array: [],
            decimal: -1
        });
        if (decimal > -1) {
            array.splice(decimal, 0, decimalDelimiter);
            if (decimal === 0) {
                array.unshift('0');
            }
        }
        let text = array.join('');
        text = text.replace(/^0+(\d.*)/, '$1');

        return text.split(decimalDelimiter)
            .map((s: string, index: number) => index === 0 ? s.split(/(?=(?:\d{3})+(?:$))/g).join(thousandDelimiter) : s.substring(0, decimalPlaces))
            .join(decimalDelimiter);
    };

    const onHandlerChange = useCallback((e: any) => {
        e.persist();
        e.target.value = formatValue(e.target.value);
        onChange && onChange(e);
    }, [onChange]);

    const handlerClear = useCallback(() => {
        if (isClearable) {
            if (!value.length) return;
            onClear && onClear();
        }
    }, [isClearable, onClear, value]);

    const formatTarget = (value: string) => {
        let _value = value;
        _value = _value.replace(/\./g, '').replace(/,/g, '.');
        const number = parseFloat(_value);
        if (isNaN(number)) {
            return '';
        }
        const dd = number.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        return dd;
    };


    const handlerBlur = useCallback((e: any) => {
        e.target.value = formatTarget(e.target.value);
        onBlur && onBlur(e);
    }, [onBlur]);

    const IconRight = useMemo(() => {
        if (isClearable) {
            return {
                icon: ClearIcon,
                handler: handlerClear,
                tooltip: {
                    title: 'Clear'
                }
            } as any;
        }
        return iconRight;
    }, [iconRight, isClearable, handlerClear]);


    const [_placeholder, _label] = useMemo(() => [
        placeholder ? t(placeholder) : undefined,
        label ? t(label) : undefined
    ], [t, placeholder, label]);

    const iconsProps = useMemo(() => Object.assign({}, icon ? {
        startAdornment: icon?.tooltip ? (
            <Tooltip title={icon.tooltip.title || ''} placement={icon.tooltip?.placement || 'top'}>
                <InputAdornment position="start" onClick={icon.handler}>
                    <icon.icon color={icon.color || 'inherit'} />
                </InputAdornment>
            </Tooltip>
        ) : (<InputAdornment position="start" style={{ cursor: 'pointer' }} onClick={icon.handler}>
            <icon.icon color={icon.color || 'inherit'} />
        </InputAdornment>)
    } : {}, IconRight ? {
        endAdornment: (
            <InputAdornment position="end" style={{ cursor: 'pointer' }} onClick={IconRight.handler}>
                <IconRight.icon color={IconRight.color || 'inherit'} />
            </InputAdornment>
        )
    } : {}), [icon, IconRight]);


    const _onFocusHandler = React.useCallback((e: any) => {
        if (selectOnFocus && inputRef && (inputRef as any).current) {
            setSelectionOnFocus(true);
        }
        onFocus && onFocus(e);
    }, [onFocus, selectOnFocus, setSelectionOnFocus, inputRef]);

    const _helperText = useMemo(() => useHelperText ? error && typeof error === 'string' ? t(error) : helperText ? t(helperText) : <>&nbsp;</> : null, [t, error, helperText, useHelperText]);


    useEffect(() => {
        if (!selectionOnFocus || !selectOnFocus || !inputRef) {
            return;
        }
        if ((inputRef as any).current) {
            const input = (inputRef as any).current.getElementsByTagName('input');
            if (input?.length) input[0].select();
        }
    }, [selectionOnFocus, setSelectionOnFocus, selectOnFocus, inputRef]);

    useEffect(()=> {
        if ((inputRef as any).current && value?.length) {
            const input = (inputRef as any).current.getElementsByTagName('input');
            if (input?.length) {
                input[0].focus();
                input[0].blur();
            }
        }
    },[inputRef]);

    return (
        <TextFieldContainer
            fullWidth={fullWidth}
            ref={inputRef}
            label={_label}
            autoComplete="off"
            InputProps={{
                ...iconsProps,
                readOnly,
                placeholder: _placeholder
            }}
            inputProps={{
                style: {
                    textAlign
                },
                maxLength
            }}
            onBlur={handlerBlur}
            error={!!error}
            value={value || ''}
            onChange={onHandlerChange}
            onFocus={_onFocusHandler}
            variant={variant}
            FormHelperTextProps={{
                style: {
                    display: 'block',
                    minHeight: '20px'
                }
            }}
            isDark={isDark}
            size={size}
            helperText={_helperText}
            placeholder={_placeholder}
            {...rest}
        />
    );
};


export default InputCurrency;
