import { Navigate, Outlet } from 'react-router-dom';
import React, { useEffect } from 'react';
import AppBar from 'components/appbar';
import Drawer from 'components/drawer';
import { DefaultLayoutContainer, DefaultLayoutContent } from 'layouts/style';
import { isMobile } from 'react-device-detect';
import { useAppDispatch } from '../store/hooks';
import {
    selectAppStore,
    setDrivers,
    setOpen, setOpenMap,
    setSettings,
    setUser,
    setVehicles,
    setVersion
} from '../store/app/appSlice';
import ModalContainer from '../components/Dialog/ModalContainer';
import { useSelector } from 'react-redux';
import useAuthUserQuery from '../core/api/hooks/query/useAccountQuery';
import useGetDriversQuery from '../core/api/hooks/query/useGetDriversQuery';
import useGetVehiclesQuery from '../core/api/hooks/query/useGetVehiclesQuery';
import useGetSettingsQuery from '../core/api/hooks/query/useGetSettingsQuery';


const Header = () => {
    const dispatch = useAppDispatch();
    const { open } = useSelector(selectAppStore);

    const toggleDrawer = () => {
        dispatch(setOpen(!open));
    };

    return (
        <>
            <AppBar open={open} toggleDrawer={toggleDrawer} />
            <Drawer open={open} toggleDrawer={toggleDrawer} />
        </>
    );

};


const DefaultLayout = () => {
    const dispatch = useAppDispatch();
    const { token, user, open, settings, drivers, vehicles } = useSelector(selectAppStore);

    const userAuth = useAuthUserQuery();
    const useDrivers = useGetDriversQuery();
    const useVehicles = useGetVehiclesQuery();
    const useSettings = useGetSettingsQuery();

    useEffect(() => {
        if (!useDrivers || !(useDrivers as any)?.data?.rows?.length || !!drivers?.length) return;
        dispatch(setDrivers(useDrivers.data?.rows));
    }, [drivers, useDrivers]);

    useEffect(() => {
        if (!useSettings || !useSettings?.data || settings) return;
        dispatch(setSettings(useSettings.data));
    }, [useSettings, settings]);


    useEffect(() => {
        if (!useVehicles || !(useVehicles as any)?.data?.rows?.length || !!vehicles?.length) return;
        dispatch(setVehicles(useVehicles.data?.rows));
    }, [vehicles, useVehicles]);

    useEffect(() => {
        if (!userAuth?.id || (user as any)?.id) return;
        dispatch(setVersion(userAuth.version));
        dispatch(setUser(userAuth));
    }, [userAuth, user]);


    useEffect(() => {
        const storedValue = localStorage.getItem('mapOpen');
        if (storedValue) {
            dispatch(setOpenMap(!!storedValue));
        }
    }, []);

    useEffect(() => {
        const handleStorageChange = (event: any) => {
            if (event.key === 'mapOpen') {
                dispatch(setOpenMap(!!event.newValue));
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [dispatch]);



    if (isMobile) {
        return <Navigate to="/only-desktop" replace />;
    }

    if (!token) {
        return <Navigate to="/login" replace />;
    }

    return (
        <DefaultLayoutContainer id="defaultLayout">
            <Header />
            <DefaultLayoutContent open={open}>
                <Outlet />
            </DefaultLayoutContent>
            <ModalContainer />
          {/*  {openMapInWindow && <Window onClose={onClose}/>}*/}
        </DefaultLayoutContainer>
    );
};


export default DefaultLayout;
