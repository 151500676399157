import styled from 'styled-components';
import { Box } from '@mui/material';


export const Flex = styled.div`
  display: flex;
`;



export const SlideBox = styled(Box)`
    width: 100%;
    height: 100%;
`;