import { useQuery } from '@tanstack/react-query';
import axiosClient from '../../../config/axios';
import { createUrl } from '../../../utils/createUrl';

const useGetNotifications = () => {
    const { data, isLoading, isFetched, isFetching, refetch } = useQuery({
        queryKey: ['get-notifications'],
        queryFn: async () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const res: any = await axiosClient.get(createUrl({
                url: '/dispatcher/notifications',
                version: 'v1'
            }));
            return res.data?.data || {};
        }
    });
    return { data, isLoading, isFetched, isFetching, refetch };
};

export default useGetNotifications;