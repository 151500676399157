import React, { FC } from 'react';
import { Slide, SlideProps } from '@mui/material';
import { SlideBox } from '../../assets/style';

const CustomSlide: FC<React.PropsWithChildren<SlideProps>> = ({
                                               in: inProp = true,
                                               direction = 'left',
                                               mountOnEnter = true,
                                               unmountOnExit = true,
                                               children,
                                               ...restProps // Include any additional props for flexibility
}) => {
    return (
        <Slide
            direction={direction}
            in={inProp}
            mountOnEnter={mountOnEnter}
            unmountOnExit={unmountOnExit}
            {...restProps}
        >
            <SlideBox>{children}</SlideBox>
        </Slide>
    );
};

export default CustomSlide;