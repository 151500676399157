import React, { useCallback, useContext, useEffect } from 'react';
import {
    NotificationListContent,
    NotificationListHeader,
    NotificationListHeaderCloseButton,
    NotificationListHeaderTitle,
    NotificationsListContainer
} from './style';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTranslation } from 'react-i18next';
import NotificationListItem from './NotificationListItem';
import useGetNotifications from '../../../../core/api/hooks/query/notifications/useGetNotifications';
import UiDataContext from '../../../../context/UiContext/context';

const NotificationsList = ({onClose, setRefetchCount}: any) => {

    const {t} = useTranslation();
    const { data, isFetched, refetch } = useGetNotifications();
    const {messages} = useContext(UiDataContext);

    const setRefetch = useCallback(()=> {
        refetch();
        setRefetchCount();
    },[refetch, setRefetchCount]);

    useEffect(() => {
        if(!messages || !messages?.length) return;
        refetch();
    }, [messages]);



    return (
        <NotificationsListContainer>
            <NotificationListHeader>
                <NotificationListHeaderTitle>{`${t('notificationsLabel')} (${data?.length || 0})`}</NotificationListHeaderTitle>
                <NotificationListHeaderCloseButton onClick={onClose}>
                    <CloseOutlinedIcon />
                </NotificationListHeaderCloseButton>
            </NotificationListHeader>
            <NotificationListContent>
               {isFetched && !data.length ?  <>No data</> :(data || []).map((item: any, index: number) => <NotificationListItem key={index} item={item} setRefetch={setRefetch} />)}
            </NotificationListContent>
        </NotificationsListContainer>
    );
};

export default NotificationsList;