import React, { useCallback, useMemo } from 'react';
import { DefineMapPinContainer, DefineMapPinDrop } from './style';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { useModal } from '../../../../../../../hooks/useModal';
import { useFormikContext } from 'formik';
import MapPinDrop from './MapPinDrop';


const MapPin = ({ realField }: any) => {

    const { values, setFieldValue } = useFormikContext() as any;
    const { openModal: openDialogMapPinDrop, closeModal: closeDialogMapPinDrop } = useModal({
        modal: MapPinDrop,
        isActionsButtonsVisible: false,
        isCloseAction: true,
        noPadding: true
    });

    const locationMap = useMemo(() => {
        if (!values?.[`${realField}Object`]) return {};
        const dd = values?.[`${realField}Object`];
        return {
            lat: dd.lat,
            lon: dd.lng
        };
    }, [values, realField]);

    const changeData = useCallback(async (address: string, obj: any) => {
        if(!realField || !address || !obj) return;
        await setFieldValue(realField, address, true);
        await setFieldValue(`${realField}Object`, obj, true);
    }, [setFieldValue, realField]);


    const handlerOpenDialog = useCallback(() => {
        openDialogMapPinDrop(({
            closeModal: closeDialogMapPinDrop,
            changeData,
            dataEntry: {
                locationMap
            }
        }));
    }, [openDialogMapPinDrop, closeDialogMapPinDrop, setFieldValue, locationMap]);


    return (
        <DefineMapPinContainer>
            <DefineMapPinDrop onClick={handlerOpenDialog}>
                <PinDropIcon />
            </DefineMapPinDrop>
        </DefineMapPinContainer>
    );
};

export default MapPin;