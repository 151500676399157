import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    OrderMapContainer,
    OrderViewDriverMapContainer,
    OrderViewMapActionsButton,
    OrderViewMapTopActionsContainer
} from './style';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { selectIsMapOpen, setOpenMap } from '../../../../../store/app/appSlice';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import OrderMap from '../../../../OrderMap';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../store/hooks';
import { useLocation } from 'react-router';

const OrderViewMap = () => {
    const isMapOpen = useSelector(selectIsMapOpen);
    const location = useLocation();
    const [compLoading, setCompLoading] = useState(true);
    const dispatch = useAppDispatch();
    const style = useRef({
        width: `${window.innerWidth}px`,
        height: `${window.innerHeight}px`
    } as any);

    const openMap = useCallback(() => {
        localStorage.setItem('mapOpen', '1');
        dispatch(setOpenMap(true));
        window.open(`${window.origin}/map`, '_blank');
    }, [dispatch]);

    useEffect(() => {
        setCompLoading(false);
    }, []);

    useEffect(() => {
         dispatch(setOpenMap(!!localStorage.getItem('mapOpen') || location.pathname === '/map') );
    }, [location]);

    return !isMapOpen ? (
        <OrderViewDriverMapContainer>
            <OrderMapContainer>
                {!compLoading ? <OrderMap style={isMapOpen && style}/> : <></>}
                <OrderViewMapTopActionsContainer>
                    <OrderViewMapActionsButton title={'Otvori u novom prozoru'} onClick={openMap}>
                        <OpenInNewIcon />
                    </OrderViewMapActionsButton>
                </OrderViewMapTopActionsContainer>
            </OrderMapContainer>
        </OrderViewDriverMapContainer>
    ) : null;
};


export default OrderViewMap;