import React, { useRef } from 'react';
import { useField } from 'formik';
import InputCurrency from '../input/InputCurrency';



const FormikInputCurrency = ({ name, maxLength,...rest } :any)=> {
  const inputRef = useRef();
  const [field, meta] = useField(name);

  const newProps = {
    ...field,
    ...rest,
    error: Boolean(meta.error) && meta.touched ? meta.error : false,
    helperText: Boolean(meta.touched) && meta.error ? meta.error : ''
  };

  const onBlur = (e: any) => {
    if(e.target.value) field.onChange(e);
    field.onBlur(e);
  };


  return (
    <InputCurrency
      type={'text'}
      {...newProps}
      inputRef={inputRef}
      value={newProps.value || ''}
      onBlur={onBlur}
      maxLength={maxLength}
    />
  );
};


export default FormikInputCurrency;
