import styled, { css } from 'styled-components';
import { Flex } from '../../../../../../assets/style';
import { Colors } from '../../../../../../theme/palette';
import ButtonIcon from '../../../../../../components/button/ButtonIcon';


export const PreviewOrderItemBox  = styled(Flex)<{isCompleted?: number}>`
    width: 100%;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    border: 1px solid ${Colors.gradient.yellowDark};
    border-radius: 20px;
`;

export const PreviewItemRow = styled(Flex)<{alignItems?: string, justify?: string, gap?: string}>`
    align-items: ${props => props?.alignItems || 'flex-start'};
    justify-content: ${props => props?.justify || 'space-between'};
    gap: ${props => `${props.gap}px` || '10px'};
`;

export const PreviewOrderItemAddress = styled(Flex)<{isCompleted?: number}>`
    ${props => !!props.isCompleted && css`
        color: ${Colors.green70};
    `}
`;


export const PreviewItemDescription = styled(Flex)`
    min-height: 60px;
    padding: 20px 10px;
`;

export const PreviewOrderItemCompletedBox = styled(Flex)<{isCompleted?: number}>`
    border: 1px solid ${Colors.gradient.yellowDark};
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background: transparent;
    
    ${props=> !!props.isCompleted && css`
        border-color: ${Colors.green70};
        background: ${Colors.green70};
    `}
`;

export const PreviewOrderEditButtonItemDescription = styled(ButtonIcon)`
    &&& {
        max-height: 25px;
        max-width: 25px;
        color: ${Colors.white};
    }
`;


