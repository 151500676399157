import styled, { css } from 'styled-components';
import {
  Select,
  FormControl, MenuItem, ListItemText, ListItemIcon, FormHelperText, IconButton, InputLabel
} from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const SelectFormControl = styled(FormControl)<{isDark?: boolean}>`
    &&& {
        border-radius: 20px;
        
        ${props => !props.isDark && css`
            background-color: white;
            color: black;
            .MuiFormControl-root {
                color: black;
                border-color: black;
                &.MuiFocused {
                    .MuiOutlinedInput-notchedOutline {
                        border-color: black;
                    }
                }
            }
            
            
            .MuiOutlinedInput-root {
                background-color: white;
                border-color: #000000;
                input {
                    color: #000000;
                }

                .MuiListItemText-root {
                    color: #000000;
                }

                .MuiSvgIcon-root {
                    color:  #000000;
                }
            }
            
            
            
            .MuiFormControl-input {
                color: black;
                border-color: black; 
            }
        `}
    }
`;


export const SelectContainer = styled(Select)<{error?: boolean}>`
  &&& {
    position: relative;
    text-align: left;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 57px;
    max-height: 57px;
    width: 100%;
    color: white;
    background-color: #000000;
    border-radius: 20px;
      
    &.Mui-disabled {
        border-color: transparent;
        .MuiSvgIcon-root {
            color: rgba(175, 175, 175, 0.65);
        }
    }  
      

    .MuiSelect-select {
      display: flex;
      align-items: center;
      gap: 10px;
      font: normal 400 18px/22.5 'Readex Pro';
      max-height: 50px;  
    }
    
    .MuiSvgIcon-root {
      color: #FFFFFF;
    }
      
      
      
    .MuiOutlinedInput-input {        
        &.Mui-disabled {
            cursor: not-allowed;
        }
              
      .MuiListItemIcon-root {
        .MuiSvgIcon-root {
          display: none;
        }
      }
    }

     
      
      &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
              border: none;
          }
      }

      &.Mui-error {
          &.MuiOutlinedInput-root {
              border-color: #d32f2f;

              .MuiOutlinedInput-notchedOutline {
                  border: none;
              }
              
          }
          

          .MuiSvgIcon-root {
            color: #d32f2f;
          }
    }

  }
`;


export const SelectInputLabel = styled(InputLabel)<{isDark?: boolean,value: any,error?: boolean}>`
    &&& {
        color: white;
        background: #000000;
        transform: translate(14px, 15px) scale(1);
        font: normal normal 400 16px/22.5px 'Readex Pro';
        padding: 0 5px 0 2px;

        ${props => !props.isDark && css`
            color: #000000;
            background: white;
        `}
        ${props => props.error && css`
            color: #d32f2f;
        `}
        ${props => props.value && css`
            transform: translate(14px, -9px) scale(0.75);
        `}
        &.Mui-disabled {
            cursor: not-allowed;
            color: rgba(175, 175, 175, 0.65);
        }

        &.Mui-focused {
            transform: translate(14px, -9px) scale(0.75);
            ${props => props.error && css`
                color: #d32f2f;
            `}
        }
    }
`;

export const SelectMenuItem = styled(MenuItem)`
    &&& {
        position: relative;
        font-size: 80%;
        max-width: 100%;
        gap: 10px;
        color: #FFFFFF;
        border-bottom: 1px solid #FFFFFF;

        &.Mui-selected {
            background: rgba(255, 255, 255, 0.09);
        }

        .MuiSelect-select {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .MuiSvgIcon-root {
            margin-left: 3px;
            font-size: 20px;

            svg {
                width: 20px;
                height: 20px;
                color: #FFFFFF;
            }
        }


    }
`;

export const SelectMenuItemText = styled(ListItemText)`
    &&& {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 15px;
      color: #FFFFFF;
    }
`;
export const SelectMenuItemIcon = styled(ListItemIcon)`
  &&& {
    position: absolute;
    right: 2px;
    min-width: auto;
    justify-content: flex-end;
    color: #FFFFFF;
  }
`;

export const SelectHelperText = styled(FormHelperText)`
  &&& {
    min-height: 20px;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const SelectClearButton = styled(IconButton)<{isUseHelper?: number}>`
    &&& {
      width: 20px;
      height: 20px;
      padding: 2px;
      position: absolute;
      z-index: 100;
      right: 10px;
      background: black;
      top: 20px;
        
      ${props => !props.isUseHelper && css`
          top: 50%;
          transform: translateY(-50%);
      `}
    }
`;


export const SelectCountryImage = styled(LazyLoadImage)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 20px;
  }
`;
