import { IS_TEST } from '../../../../../config';

export const initialValuesOrderFree = IS_TEST ? {
    'customerName': 'Stefan Milutinovic',
    'customerPhone': '0644229144',
    'deliveryAmount': '400',
    '1720895846915_description': 'Kupi 1',
    '1720895846915_address': 'Test adresas',
    '1720895846915_pickUpTime': '20',
    '1720895846915_amount': '850,00',
    '1720896191152_description': 'Kupi 2',
    '1720896191152_address': 'Test addressa',
    '1720896191152_pickUpTime': '35',
    '1720896191152_amount': '950,00',
    'address': 'Kosovska 16, Kruševac, Srbija',
    'addressObject': {
        'description': 'Kosovska 16, Kruševac, Srbija',
        'matched_substrings': [
            {
                'length': 8,
                'offset': 0
            }
        ],
        'place_id': 'Eh5Lb3NvdnNrYSAxNiwgS3J1xaFldmFjLCBTcmJpamEiLiosChQKEgmtQB5a8IdWRxGulQ2Rg4XKzxIUChIJf0BKY_GHVkcRnIlDK6Quve4',
        'reference': 'Eh5Lb3NvdnNrYSAxNiwgS3J1xaFldmFjLCBTcmJpamEiLiosChQKEgmtQB5a8IdWRxGulQ2Rg4XKzxIUChIJf0BKY_GHVkcRnIlDK6Quve4',
        'structured_formatting': {
            'main_text': 'Kosovska 16',
            'main_text_matched_substrings': [
                {
                    'length': 8,
                    'offset': 0
                }
            ],
            'secondary_text': 'Kruševac, Srbija'
        },
        'terms': [
            {
                'offset': 0,
                'value': 'Kosovska'
            },
            {
                'offset': 9,
                'value': '16'
            },
            {
                'offset': 13,
                'value': 'Kruševac'
            },
            {
                'offset': 23,
                'value': 'Srbija'
            }
        ],
        'types': [
            'route',
            'geocode'
        ]
    }
} : {
    customerName: '',
    customerPhone: '',
    address: '',
    orderDeliveryAmount: '',
    orderDate: new Date()
};