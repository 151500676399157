import React from 'react';
import { SettingsContainer, SettingsHeader, SettingsHeaderType, SettingsViewContainer } from './style';
import Translation from '../../components/Translation';
import { Translate } from '../../internationalization/translate';
import Panel from '../../components/Panel';
import SettingsLayout from './view/SettingsLayout';
import CustomSlide from '../../components/Transitions/Slide';

const Settings = () => {
    return (
        <CustomSlide>
            <SettingsContainer>
                <SettingsHeader>
                    <Translation use={Translate.settingsPageTitle} as={SettingsHeaderType} />
                </SettingsHeader>
                <SettingsViewContainer>
                    <Panel>
                        <SettingsLayout />
                    </Panel>
                </SettingsViewContainer>
            </SettingsContainer>
        </CustomSlide>
    );
};


export default Settings;
