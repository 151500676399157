import React, { useCallback, useContext, useEffect } from 'react';
import {
    DefineOrderFreeFormContainer,
    DefineOrderRow,
    DefineOrderSectionLabel,
    OrderFreeBackButton,
    OrderFreeForm
} from '../style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Translation from '../../../../../../components/Translation';
import { Translate } from '../../../../../../internationalization/translate';
import FormikTextField from '../../../../../../components/Formik/FormikTextField';
import CustomerPhoneField from '../components/CustomerPhoneField';
import AddressSearch from '../../../../../../components/Autocomplete/AddressSearch';
import MapPin from '../components/MapPinDrop';
import OrderFreeItemsContent from '../OrderFreeItemsContent';
import OrderFreeContext from '../context/context';
import { useFormikContext } from 'formik';
import { setOrderStarted } from '../../../../../../store/app/appSlice';
import { useAppDispatch } from '../../../../../../store/hooks';


const  withPreload = (Component: any) => {
    return function WrappedComponent(props: any) {
        const { setFromStorage } = useContext(OrderFreeContext);

        const initialData = (() => {
            try {
                const dd = localStorage.getItem('ORDER_STATE_ITEMS');
                return dd ? JSON.parse(dd) : [];
            } catch (e) {
                return undefined;
            }
        })();

        const initialFormik = (() => {
            try {
                const _formik = localStorage.getItem('ORDER_FORMIK');
                const dd = _formik ? JSON.parse(_formik) : {} as any;
                return _formik ? {
                    ...dd,
                    orderDate: dd?.orderDate ? new Date(dd.orderDate as any) : new Date()
                } : undefined;
            } catch (e) {
                return undefined;
            }
        })();

        useEffect(() => {
            if(!initialFormik) return;
            const isStorage = Object.keys(initialFormik).some(x=> x !== 'orderDate' && !!initialFormik[x]);
            if(!isStorage) return;
            setFromStorage(isStorage);
        }, [initialFormik]);

        return <Component {...props} initialData={initialData} initialFormik={initialFormik} />;
    };
};


const _OrderFreeFormDefinition = ({goBack, initialData, initialFormik}: any) => {
    const {values, setValues} = useFormikContext();
    const { saveData, setData } = useContext(OrderFreeContext);
    const dispatch = useAppDispatch();

    useEffect(() => {
            if(initialData) {
                setData(initialData);
            }
            if(initialFormik) {
                setValues(initialFormik);
            }
    }, []);

    const saveDataFn = useCallback(()=> {
        const isNeedToStore = values && Object.keys(values).some(x=> x !== 'orderDate' && !!(values as any)[x]);
        if(isNeedToStore) {
            localStorage.setItem('ORDER_FORMIK', JSON.stringify(values));
            saveData();
            dispatch(setOrderStarted(true));
        }
    }, [values, saveData]);

    const handlerGoBack = useCallback(()=> {
        saveDataFn();
        goBack();
    },[goBack, saveDataFn]);

    useEffect(() => {
        return () => {
            if(localStorage.getItem('ORDER_REMOVE')) {
                localStorage.removeItem('ORDER_FORMIK');
                localStorage.removeItem('ORDER_STATE_ITEMS');
                dispatch(setOrderStarted(false));
            }
        };
    }, []);

    return (
        <OrderFreeForm>
            <DefineOrderFreeFormContainer>
                <OrderFreeBackButton title={'back'} onClick={handlerGoBack}><ArrowBackIcon /></OrderFreeBackButton>
                <Translation use={Translate.defineOrderSectionCustomerTitle} as={DefineOrderSectionLabel} />
                <DefineOrderRow margin justify={'space-between'}>
                    <FormikTextField
                        name={'customerName'}
                        variant={'outlined'}
                        label={Translate.defineOrderCustomerNameLabel}
                    />
                    <CustomerPhoneField />
                    <AddressSearch isFormik name={'address'} label={Translate.destination} showSwitch />
                    <MapPin realField={'address'}/>
                    <FormikTextField
                        name={'customerBuildingNumber'}
                        variant={'outlined'}
                        label={Translate.defineOrderCustomerBuildingNumberLabel}
                        className={'customer-building-number'}
                    />
                </DefineOrderRow>
                <OrderFreeItemsContent />
            </DefineOrderFreeFormContainer>
        </OrderFreeForm>
    );
};

const OrderFreeFormDefinition = withPreload(_OrderFreeFormDefinition);

export default OrderFreeFormDefinition;