import styled from 'styled-components';
import { Flex } from '../../../../../../../assets/style';
import ButtonIcon from '../../../../../../../components/button/ButtonIcon';
import { Colors } from '../../../../../../../theme/palette';
import { Form } from 'formik';

export const PreviewOrderItemEditDescriptionForm = styled(Form)`
    width: 100%;
`;


export const PreviewOrderItemEditDescriptionContainer = styled(Flex)`
    gap: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const PreviewOrderItemEditDescButtons = styled(Flex)`
    gap: 15px;
    align-items: center;
`;

export const ConfirmButton = styled(ButtonIcon)`
    &&& {
        color: ${Colors.white};
        background: ${Colors.green70};
    }
`;

export const CancelButton = styled(ButtonIcon)`
    &&& {
        color: ${Colors.white};
        background: ${Colors.red};
    }
`;
