import styled, { css } from 'styled-components';
import { Box, Typography } from '@mui/material';
import { Flex } from '../../assets/style';
import Button from '../../components/button';
import { Colors } from '../../theme/palette';

export const OrdersContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 20px;
`;

export const OrdersHeader = styled(Flex)`
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    min-height: 76px;
`;

export const OrdersHeaderAddButton = styled(Button)<{isStartedOrder?: number}>`
    &&& {
        display: flex;
        justify-content: space-between;
        min-width: 85px;
        max-width: 150px;
        gap: 5px;
        padding: 5px 10px;
        color: black;
        border-radius: 10px;
        font-family: Readex Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        .MuiButton-startIcon {
            margin-right: 0;
            width: 24px;
            height: 24px;
        }
        
        ${props => props.isOrderStarted && css`
            background: ${Colors.red};
            color: ${Colors.white};
        `}
    }
`;

export const OrdersHeaderType = styled(Typography).attrs({
    variant: 'h4'
})`
    color: #FFF;
    text-align: center;
    font: normal 400 40px/normal 'Readex Pro';
`;

export const OrdersViewsContainer = styled(Flex)`
    flex: 2;
    width: 100%;
    padding: 15px 20px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    border-radius: 20px;
    border: 1px solid ${Colors.gradient.yellowDark};
    max-height: calc(100% - 100px);

    @media (max-width: 1921px) {
        max-width: 1800px;
    }

    @media (max-width: 1800px) {
        max-width: 1700px;
    }
`;

