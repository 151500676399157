import React, { useCallback, useContext, useEffect } from 'react';
import useGetNotificationsCount from '../../../../core/api/hooks/query/notifications/useGetNotificationsCount';
import { NotificationButtonBadge, NotificationButtonIcon, NotificationIcon } from './style';
import { Menu } from '@mui/material';
import { Colors } from '../../../../theme/palette';
import NotificationsList from './NotificationsList';
import UiDataContext from '../../../../context/UiContext/context';


const Notifications = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const {messages} = useContext(UiDataContext);

    const { data, refetch } = useGetNotificationsCount();


    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        if(!data) return;
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl, data]);
    const handleClose = useCallback((_: any, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        setAnchorEl(null);
    }, [setAnchorEl]);

    useEffect(() => {
        if(!data) {
            setAnchorEl(null);
            return;
        }
    }, [data,setAnchorEl]);

    useEffect(() => {
        if(!messages || !messages?.length) return;
        refetch();
    }, [messages]);

    return (
        <>
            <NotificationButtonIcon
                aria-controls={open ? 'notifications' : undefined}
                onClick={handleClick}
                disabled={!data}
            >
                {data ? <NotificationButtonBadge badgeContent={<>{data}</>}>
                    <NotificationIcon />
                </NotificationButtonBadge> : <NotificationIcon />}
            </NotificationButtonIcon>
            <Menu
                id="basic-menu1"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {
                        padding: 0
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        minWidth: 520,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        border: `1px solid ${Colors.gradient.yellowLight}`,
                        borderRadius: '8px'
                    }
                }}
            >
                <NotificationsList onClose={handleClose} setRefetchCount={refetch} />
            </Menu>
        </>
    );
};

export default Notifications;