import styled, { css } from 'styled-components';
import { MapContainer } from 'react-leaflet';



export const OrderViewMapContainer = styled(MapContainer)<{isMap?: number}>`
    &&& {
        width: 100% !important;
        height: 100% !important;
        
        ${props=> props.isMap && css`
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9999999;
        `}
    }
`;
