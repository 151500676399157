import styled, {css} from 'styled-components';
import {MapContainer} from 'react-leaflet';
import {Flex} from '../../../../../assets/style';
import {Autocomplete} from '@mui/material';
import CheckBox from '../../../../../components/Checkbox';




export const RestaurantLocationAutoComplete = styled(Autocomplete)<{value?: any, disableClearable?: boolean}>`
    &&& {
        width: 100%;

        .MuiInputLabel-root {
            top: 0;
            
            transform: translate(14px, 14px) scale(1);
            
            ${props => props.value && css`
                transform: translate(16px, -9px) scale(0.75);
            `}
           
            &.Mui-focused {
                top:0 ;
                transform: translate(16px, -10px) scale(0.75);
            }
        }

        .MuiOutlinedInput-root  {
            input {
                padding: 0 35px 0 19px;
            }
        }
    }
`;

export const RestaurantLocationFormContainer = styled(Flex)<{hideTitle?: boolean}>`
    flex: 2;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    ${props=> props?.hideTitle && css`
        height: 100%;
    `}
`;

export const RestaurantLocationFormTitleContainer = styled(Flex)`
    position: relative;
    width: 100%;
    justify-content: center;

    .locationMapCheckBox {
        position: absolute;
        right: 25px;
        top: 5px;
    }
`;
export const RestaurantLocationTypeCheckBox = styled(CheckBox)`
      &&& {
          min-width: 155px;
      }
`;

export const RestaurantLocationFormTitle = styled(Flex)`
  font: normal 400 36px/46px 'Readex Pro';
  color: #FFFFFF;
`;

export const RestaurantLocationFormContent = styled(Flex)<{hideTitle?: boolean}>`
    flex: 2;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    padding: 25px 135px 15px;
    ${props => props.hideTitle && css`
        padding: 5px 60px;
    `}
`;


export const RestaurantLocationPreviewAddress = styled(Flex)`
    padding: 10px 0;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const RestaurantLocationContainerMap = styled(Flex)`
        position: relative;
        width: 100%;
        height: 355px;
`;

export const RestaurantLocationMapContainer = styled(MapContainer)`
    &&& {
        width: 100%;
        height: 100%;
    }
`;

export const RestaurantLocationMapLayer = styled(Flex)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(103, 103, 103, 0.27);
    cursor: not-allowed;
    z-index: 1001;
`;