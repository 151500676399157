import { useCallback, useMemo, useReducer, useRef } from 'react';
import {
    initialDataShiftsForDay,
    SHIFT_SCHEDULING_FOR_DAY_EVENTS,
    TShiftSchedulingActions,
    TShiftSchedulingReducer
} from './d';
import ShiftSchedulingForDayContext from './context';
import { guid } from '../../../../../../utils';

const initialShiftNewRecord = {
    vehicleId: '',
    driverId: '',
    amount: ''
};

const reducer = (state: TShiftSchedulingReducer, action: TShiftSchedulingActions) => {
    switch (action.type) {
        default:
            return state;

        case SHIFT_SCHEDULING_FOR_DAY_EVENTS.SET_ACTIVE_SHIFT: {
            return {
                ...state,
                activeShift: action.payload
            };
        }

        case SHIFT_SCHEDULING_FOR_DAY_EVENTS.ADD_NEW_ITEM_BY_SHIFT: {
            const { activeShift } = state;
            return {
                ...state,
                shifts: {
                    ...state.shifts,
                    [activeShift]: [
                        ...state.shifts[activeShift],
                        {
                            ...initialShiftNewRecord,
                            guid: guid(),
                            shiftId: activeShift,
                            id: action.payload.id,
                            amount: action.payload.amount
                        }
                    ]
                }
            };
        }

        case SHIFT_SCHEDULING_FOR_DAY_EVENTS.REMOVE_ITEM_BY_SHIFT: {
            const { activeShift } = state;
            const index = state.shifts[activeShift]?.findIndex((x: any) => x.id === action.payload);
            if (index === -1) return state;
            const array = [...state.shifts[activeShift]];
            array.splice(index, 1);
            return {
                ...state,
                shifts: {
                    ...state.shifts,
                    [activeShift]: [...array]
                }
            };
        }


        case SHIFT_SCHEDULING_FOR_DAY_EVENTS.CHANGE_FIELD_VALUE_OF_ITEM: {
            const { activeShift } = state;
            const index = state.shifts[activeShift]?.findIndex((x: any) => x.guid === action.payload?.itemId);
            if (index === -1) return state;
            const array = [...state.shifts[activeShift]];
            array.splice(index, 1, {
                ...array[index],
                [action.payload.field]: action.payload.value
            });
            return {
                ...state,
                shifts: {
                    ...state.shifts,
                    [activeShift]: [...array]
                }
            };
        }

        case SHIFT_SCHEDULING_FOR_DAY_EVENTS.SET_SHIFT_DATE: {
            return {
                ...state,
                shiftDate: action.payload
            };
        }

        case SHIFT_SCHEDULING_FOR_DAY_EVENTS.SET_SHIFTS: {
            return {
                ...state,
                shifts: action.payload
            };
        }

        case SHIFT_SCHEDULING_FOR_DAY_EVENTS.NEED_REFETCH: {
            return {
                ...state,
                needRefetch: true
            };
        }

        case SHIFT_SCHEDULING_FOR_DAY_EVENTS.RESET_NEED_REFETCH: {
            return {
                ...state,
                needRefetch: false
            };
        }

        case SHIFT_SCHEDULING_FOR_DAY_EVENTS.REST_SHIFTS: {
            return {
                ...state,
                ...initialDataShiftsForDay
            };
        }

    }
};

export const useShiftSchedulingForDayContext = (entryData = {}) => {
        const dataReduceState = useRef({
            ...initialDataShiftsForDay,
            ...entryData
        });


        const [state, dispatch] = useReducer(reducer, dataReduceState.current as TShiftSchedulingReducer);

        const setActiveShift = useCallback((payload: number) => dispatch({
            type: SHIFT_SCHEDULING_FOR_DAY_EVENTS.SET_ACTIVE_SHIFT,
            payload
        }), [dispatch]);

        const setShifts = useCallback((payload: any) => dispatch({
            type: SHIFT_SCHEDULING_FOR_DAY_EVENTS.SET_SHIFTS,
            payload
        }), [dispatch]);

        const setShiftDate = useCallback((payload: Date | string) => dispatch({
            type: SHIFT_SCHEDULING_FOR_DAY_EVENTS.SET_SHIFT_DATE,
            payload
        }), [dispatch]);

        const addNewItemByShift = useCallback((id: number, amount: number) => dispatch({
            type: SHIFT_SCHEDULING_FOR_DAY_EVENTS.ADD_NEW_ITEM_BY_SHIFT,
            payload: {
                id,
                amount
            }
        }), [dispatch]);

        const removeItemByShift = useCallback((id: number) => dispatch({
            type: SHIFT_SCHEDULING_FOR_DAY_EVENTS.REMOVE_ITEM_BY_SHIFT,
            payload: id
        }), [dispatch]);

        const changeItemDriver = useCallback((itemId: any, driverId: number | string) => dispatch({
            type: SHIFT_SCHEDULING_FOR_DAY_EVENTS.CHANGE_FIELD_VALUE_OF_ITEM,
            payload: {
                itemId,
                field: 'driverId',
                value: driverId
            }
        }), [dispatch]);

        const changeItemAmount = useCallback((itemId: any, amount: number | string) => dispatch({
            type: SHIFT_SCHEDULING_FOR_DAY_EVENTS.CHANGE_FIELD_VALUE_OF_ITEM,
            payload: {
                itemId,
                field: 'amount',
                value: amount
            }
        }), [dispatch]);

        const resetShifts = useCallback(() => dispatch({
            type: SHIFT_SCHEDULING_FOR_DAY_EVENTS.REST_SHIFTS
        }), [dispatch]);

        const changeItemVehicle = useCallback((itemId: any, vehicleId: number | string) => dispatch({
            type: SHIFT_SCHEDULING_FOR_DAY_EVENTS.CHANGE_FIELD_VALUE_OF_ITEM,
            payload: {
                itemId,
                field: 'vehicleId',
                value: vehicleId
            }
        }), [dispatch]);

    const changeOtherData = useCallback((itemId: any, data: {field: string, value: number|string|boolean}) => dispatch({
        type: SHIFT_SCHEDULING_FOR_DAY_EVENTS.CHANGE_FIELD_VALUE_OF_ITEM,
        payload: {
            itemId,
            ...data
        }
    }), [dispatch]);


    const setNeedRefetch = useCallback(() => dispatch({
        type: SHIFT_SCHEDULING_FOR_DAY_EVENTS.NEED_REFETCH
    }), [dispatch]);

    const clearNeedRefetch = useCallback(() => dispatch({
        type: SHIFT_SCHEDULING_FOR_DAY_EVENTS.RESET_NEED_REFETCH
    }), [dispatch]);


    return useMemo(() => {
            return {
                ...state,
                activeShiftItems: [...(state?.shifts?.[state.activeShift] || [])],
                setShiftDate,
                setActiveShift,
                addNewItemByShift,
                removeItemByShift,
                changeItemVehicle,
                changeItemDriver,
                changeItemAmount,
                setShifts,
                resetShifts,
                changeOtherData,
                setNeedRefetch,
                clearNeedRefetch
            };
        }, [state, setShiftDate, setActiveShift, addNewItemByShift, changeItemVehicle, removeItemByShift, changeItemDriver, changeItemAmount, setShifts, resetShifts, changeOtherData, setNeedRefetch, clearNeedRefetch]);
    }
;


export type TShiftSchedulingForDayContextType = ReturnType<typeof useShiftSchedulingForDayContext>;

const ShiftSchedulingForDateContextContainer = ({
                                                    children
                                                }: any) => {

    const providerData = useShiftSchedulingForDayContext();


    return (
        <ShiftSchedulingForDayContext.Provider value={providerData}>
            {children}
        </ShiftSchedulingForDayContext.Provider>
    );

};

export default ShiftSchedulingForDateContextContainer;