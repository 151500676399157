import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import AddressSearchGoogle from './AddressSearchGoogle';
import AddressSearchOpenStreet from './AddressSearchOpenStreet';
import { SearchAddressAutoCompleteContainer, SearchAddressType, SearchAddressTypeContainer } from './style';
import { Typography } from '@mui/material';
import FormikGoogleSearchAddress from './FormikGoogleSearchAddress';
import FormikOpenStreetSearchAddress from './FormikOpenStreetSearchAddress';


const AddressSearch = ({isFormik, name, label, showSwitch, showAddress, fullWidth}: any) => {

    const { values, setFieldValue, errors } = useFormikContext() as any;

    const [provider, dataEntry] = useMemo(() => [
        !values?.provider,
        {
            address: values?.[name || 'address'] || '',
            addressObject: values?.[name ? `${name}Object`: 'addressObject'] || null
        }
    ], [values, name]);

    const handleChangeProvider = useCallback((e: any) => {
        setFieldValue('provider', e.target.checked, false);
    }, [setFieldValue]);

    const handleChangeAddress = useCallback((value: any) => {
        setFieldValue(name || 'address', value?.[name], false);
        setFieldValue(name ? `${name}Object` : 'addressObject', value?.[name ? `${name}Object`: 'addressObject'], false);
    }, [setFieldValue, name]);

    const handleClearAddress = useCallback(() => {
        setFieldValue(name || 'address', undefined, false);
        setFieldValue(name ? `${name}Object` : 'addressObject', undefined, false);
    }, [setFieldValue, name]);

    const Component = useMemo(() => provider ?
        isFormik ? FormikGoogleSearchAddress : AddressSearchGoogle :
        isFormik ? FormikOpenStreetSearchAddress : AddressSearchOpenStreet, [provider, isFormik]);

    return (
        <SearchAddressAutoCompleteContainer fullWidth={Number(fullWidth)}>
            {!!showSwitch && (<SearchAddressTypeContainer direction="row" spacing={1} alignItems="center">
                <Typography>Google</Typography>
                <SearchAddressType
                    value={provider}
                    onChange={handleChangeProvider}
                />
                <Typography>Open</Typography>
            </SearchAddressTypeContainer>)}
            <Component
                setFieldValue={setFieldValue}
                label={label}
                dataEntry={dataEntry}
                changeData={handleChangeAddress}
                clearData={handleClearAddress}
                errors={errors}
                useHelperText
                name={name}
                showAddress={showAddress}
            />
        </SearchAddressAutoCompleteContainer>
    );
};

export default AddressSearch;