import axiosClient from 'core/api/config/axios';
import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'core/api/utils/createUrl';
import store from '../../../../../store';
import { setShiftDrivers } from '../../../../../store/app/appSlice';


const useGetCurrentDriversQuery = (requestParams = {}  as any, refetchDone?: any) => {
  const { data, isLoading, isFetched, isFetching, error, refetch } = useQuery({
    queryKey: ['get-current-drivers'],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await axiosClient.get(createUrl({
        url: '/dispatcher/current-shift-drivers',
        query: {
          perPage: 1000,
          page: 1,
          ...(requestParams || {})
        },
        version: 'v1'
      }));
      refetchDone && refetchDone();
      if(res?.data?.data) {
        store.dispatch(setShiftDrivers(res?.data?.data));
      }
      return res.data?.data || [];
    },
    retry: false
  });
  return  { data, isLoading, isFetched, isFetching, error, refetch };
};

export default useGetCurrentDriversQuery;
