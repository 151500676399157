import React, { useMemo } from 'react';
import moment from 'moment';
import {
    OrderItemPickUpLateTime,
    OrderItemPickUpTimeContainer,
    OrderItemPickUpTimeText,
    OrderItemTitle
} from './style';


const PickUpTimeView = ({
    itemAddress,
    isCompleted,
    pickUpTime,
    completedTime,
    isCustomerNotShow
}: any) => {
    const  [time, isOrange, isRed, completedTimeText] = useMemo(()=> {
       const diff = moment.duration(moment(pickUpTime).diff(moment()))?.asMinutes();
       const diffCompleted = completedTime ? moment.duration(moment(pickUpTime).diff(moment(completedTime)))?.asMinutes() : 0;
       let diffToShow  = 0;
       if(diffCompleted && diffCompleted < 0) {
           diffToShow = diffCompleted * (-1);
       }
       return [
            pickUpTime ? moment(pickUpTime).format('HH:mm') : '',
            pickUpTime && (diff >= 15 || (diff <= 15 && diff >= 11)),
            pickUpTime && diff <= 10,
            !isCustomerNotShow && completedTime && diffToShow > 0 ? `zakasnio ${Math.ceil(diffToShow)} min` : ''
        ];
    },[pickUpTime, completedTime, isCustomerNotShow]);

    return (
        <OrderItemPickUpTimeContainer>
            <OrderItemTitle isCompleted={isCompleted}>{itemAddress ? itemAddress : '#####'}</OrderItemTitle>
            <OrderItemPickUpTimeText isOrange={+isOrange} isRed={+isRed}>
                {time}
            </OrderItemPickUpTimeText>
            <OrderItemPickUpLateTime>{completedTimeText}</OrderItemPickUpLateTime>
        </OrderItemPickUpTimeContainer>
    );
};

export default PickUpTimeView;