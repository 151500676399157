import styled from 'styled-components';
import { Flex } from '../../../../../../assets/style';
import SearchInput from '../../../../../../components/input/SearchInput';
import Select from '../../../../../../components/select';


export const OrderViewListHeaderContainer = styled(Flex)`
    min-height: 40px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
`;


export const OrderViewListHeaderTitle = styled(Flex)`
    
`;

export const OrderViewListHeaderSearch = styled(SearchInput)`
    max-width: 250px;
`;

export const OrderViewListHeaderDriver = styled(Select)`
    min-width: 200px;
`;