import styled from 'styled-components';
import { Badge, Box } from '@mui/material';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import ButtonIcon from '../../../button/ButtonIcon';
import { Flex } from '../../../../assets/style';

export const NotificationButtonBadge = styled(Badge)`
  &&& {
     .MuiBadge-badge {
       background-color: #11D600;
       color: #FFFFFF;
     }
  }
`;

export const NotificationIcon = styled(DirectionsCarFilledOutlinedIcon)`
  &&& {
      color: #FFFFFF;
  }
`;
export const NotificationButtonIcon = styled(ButtonIcon)`
    &&& {
        width: 1rem;
        height: 1rem;
    }
`;



export const NotificationsListContainer = styled(Flex)`
   flex-direction: column;
   justify-content: flex-start;
   min-width: 500px;
   max-width: 520px;
   min-height: 170px;
   max-height: 335px;
   padding: 8px;
`;

export const NotificationListHeader = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    width: 100%;
`;

export const NotificationListHeaderTitle = styled.div`
    font: normal 400 22px/27.5px 'Readex Pro';
`;

export const NotificationListHeaderCloseButton = styled(ButtonIcon)`\
 &&& {
     color: #FFFFFF;
     font-weight: bold;
     
     svg {
         color: #FFFFFF;
     }
 }
`;

export const NotificationListContent = styled(Box)`
    flex: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;
`;