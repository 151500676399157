import styled, { css } from 'styled-components';
import { Flex } from '../../../../../../../assets/style';
import { SHIFT_SCHEDULING_STATUS } from '../../../../../view/SchedulingBoard/d';
import { Colors } from '../../../../../../../theme/palette';


export const PreviewShiftRowContainer = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    font: normal normal 400 20px/26px "Readex Pro";
    letter-spacing: 0.4px;
`;

export const PreviewShiftRowItem = styled(Flex)`
    flex: 2;
`;

export const PreviewShiftRowStatus = styled(Flex)`
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    position: relative;
`;

export const PreviewShiftRowStatusText = styled(Flex)`
    flex: 1;
`;


export const PreviewShiftRowStatusIndicator = styled(Flex)<{status?: number}>`
    width: 15px;
    height: 15px;
   // position: absolute;
   // right: -5px;
    border-radius: 100%;
    background: ${Colors.green};
    
    ${props => props.status === 0 && css`
        background: ${Colors.orange};
    `}
    
    ${props => (props.status === SHIFT_SCHEDULING_STATUS.END || props.status === SHIFT_SCHEDULING_STATUS.TEMP_STOP || props.status === SHIFT_SCHEDULING_STATUS.TEMP_CAN_RESUME || props.status === SHIFT_SCHEDULING_STATUS.TEMP_NOT_WORK ) && css`
        background: ${Colors.red};
    `}

    ${props => props.status === SHIFT_SCHEDULING_STATUS.PAUSE && css`
        background: ${Colors.gradient.yellowLight};
    `}
    
`;