import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    PreviewItemDescription,
    PreviewItemRow,
    PreviewOrderEditButtonItemDescription,
    PreviewOrderItemAddress,
    PreviewOrderItemBox,
    PreviewOrderItemCompletedBox
} from './style';
import moment from 'moment-timezone';
import { formatCurrency } from '../../../../../../utils';
import Collapse from '../../../../../../components/Collapse';
import { Translate } from '../../../../../../internationalization/translate';
import Translation from '../../../../../../components/Translation';
import {
    PreviewOrderColumn,
    PreviewOrderFinishImage,
    PreviewOrderFinishNoteText,
    PreviewOrderFinishText,
    PreviewOrderFinishTimeText,
    PreviewOrderItemPickUpTimeText
} from '../style';
import EditIcon from '@mui/icons-material/Edit';
import PreviewOrderItemEditDescription from './EditDescription/PreviewOrderItemEditDescription';
import { ORDER_STATUS } from '../../../../d';


const PreviewOrderItem = ({ item, index, status, orderId }: any) => {

    const [edit, setEdit] = useState<boolean>(false);

    const [time, amount, completedTime, isOrange, isRed, completedText, isCompleted, isEditable] = useMemo(() => {
        const diff = item?.pickUpTime ? moment.duration(moment(item?.pickUpTime).tz('Europe/Belgrade').diff(moment().tz('Europe/Belgrade')))?.asMinutes() : '';
        const diffCompleted = item?.completedTime ? moment.duration(moment(item?.pickUpTime).tz('Europe/Belgrade').diff(moment(item?.completedTime).tz('Europe/Belgrade')))?.asMinutes() : 0;

        return [
            item?.completedTime ? moment(item?.completedTime).tz('Europe/Belgrade').format('YYYY-MM-DD HH:mm') : item?.pickUpTime ? moment(item?.pickUpTime).tz('Europe/Belgrade').format('YYYY-MM-DD HH:mm') : '',
            item?.amount ? formatCurrency(item?.amount, false) : '',
            item?.completedTime ? moment(item?.completedTime).tz('Europe/Belgrade').format('YYYY-MM-DD HH:mm') : '',
            Number(item?.pickUpTime && (diff >= 15 || (diff <= 15 && diff >= 11))),
            Number(!item?.isCompleted && item?.pickUpTime && diff <= 10),
            item?.completedTime && diffCompleted && diffCompleted > 0 ? Math.floor(diffCompleted) : '',
            item?.isCompleted,
            !item?.isCompleted && ![ORDER_STATUS.COMPLETED, ORDER_STATUS.REJECTED, ORDER_STATUS.ARCHIVED].includes(status)
        ];
    }, [item, status]);

    const handleEdit = useCallback(() => {
        setEdit(v => !v);
    }, [setEdit]);

    const handleCancelEdit = useCallback(() => {
        setEdit(false);
    }, [setEdit]);


    useEffect(() => {

    }, []);

    return (
        <PreviewOrderItemBox isCompleted={item?.isCompleted}>
            <PreviewItemRow>
                <PreviewItemRow gap={'15'}>
                    <PreviewOrderItemCompletedBox
                        isCompleted={item.isCompleted}
                    />
                    <div>#{index}</div>
                </PreviewItemRow>
                {time ? <Translation
                    use={Translate.orderPreviewItemsPickUpTime}
                    as={PreviewOrderItemPickUpTimeText}
                    params={{ time, completed: isCompleted ? 'o' : 'i' }}
                    isOrange={isOrange}
                    isRed={isRed}
                /> : null}
                {isEditable && !edit ? <PreviewOrderEditButtonItemDescription onClick={handleEdit}>
                        <EditIcon />
                    </PreviewOrderEditButtonItemDescription>
                    : null}
            </PreviewItemRow>
            <PreviewItemRow>
                <PreviewOrderItemAddress isCompleted={item?.isCompleted}>{item.address}</PreviewOrderItemAddress>
                {amount ? <Translation use={Translate.orderPreviewItemsAmount} as={'div'} params={{ amount }} /> : null}
            </PreviewItemRow>
            <PreviewItemDescription>
                {isEditable && edit ? <PreviewOrderItemEditDescription item={item} cancelEdit={handleCancelEdit} orderId={orderId} />: item?.description }
            </PreviewItemDescription>
            {item?.isCompleted ? <Collapse label={Translate.orderPreviewItemsFinishInformation}>
                <PreviewItemRow gap={'20'} alignItems={'flex-start'}>
                    <PreviewOrderFinishImage
                        width={100}
                        height={100}
                        alt={'Finish Image'}
                        src={item.image}
                    />
                    <PreviewOrderColumn flex={2}>
                        <PreviewItemRow>
                            {completedText ? <Translation
                                use={Translate.orderPreviewItemsCompletedText}
                                as={PreviewOrderFinishText}
                                isOrange={isOrange}
                                isRed={isRed}
                                params={{ time: completedText }}
                            /> : <>&nbsp;</>}
                            <Translation
                                use={Translate.orderPreviewItemsCompletedTime}
                                as={PreviewOrderFinishTimeText}
                                params={{ time: completedTime }}
                            />
                        </PreviewItemRow>
                        <PreviewOrderFinishNoteText>
                            {item?.note}
                        </PreviewOrderFinishNoteText>
                    </PreviewOrderColumn>
                </PreviewItemRow>
            </Collapse> : null}
        </PreviewOrderItemBox>
    );
};

export default PreviewOrderItem;