import React, { useCallback, useContext } from 'react';
import {
    CancelButton,
    ConfirmButton,
    PreviewOrderItemEditDescButtons,
    PreviewOrderItemEditDescriptionContainer, PreviewOrderItemEditDescriptionForm
} from './style';
import { Check, Clear } from '@mui/icons-material';
import { Formik } from 'formik';
import FormikTextField from '../../../../../../../components/Formik/FormikTextField';
import * as yup from 'yup';
import { Translate } from '../../../../../../../internationalization/translate';
import UiDataContext from '../../../../../../../context/UiContext/context';
import useUpdateOrderItemMutation from '../../../../../../../core/api/hooks/mutations/order/useUpdateOrderItemMutation';
import { errorToast, successToast } from '../../../../../../../components/Utils/Toast';
import { useTranslation } from 'react-i18next';
import { queryClient } from '../../../../../../../core/root/ReactQueryProvider';

const schema = yup.object().shape({
    description: yup.string().required(Translate.required)
});


const PreviewOrderItemEditDescription = ({ item, orderId, cancelEdit }: any) => {
    const {t} = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const updateOrderItemMutation = useUpdateOrderItemMutation();

    const onSubmit = useCallback(async (values: any) => {
        if (!values || !item?.id) return;
        try {
            setLoading();
            const result = await updateOrderItemMutation.mutateAsync({
                id: item.id,
                description: values.description
            });
            if (result?.status === 200) {
                successToast(t(result.message));
                await queryClient.invalidateQueries({queryKey: [`get-order-${orderId}`]});
                cancelEdit();
            }
        }catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        }finally {
            resetLoading();
        }
    }, [item, setLoading, resetLoading, updateOrderItemMutation, cancelEdit]);

    const handleCancel = useCallback(() => {
        cancelEdit();
    }, [cancelEdit]);

    return (
        <Formik
            initialValues={{
                description: item.description
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
        >
            <PreviewOrderItemEditDescriptionForm>
                <PreviewOrderItemEditDescriptionContainer>
                    <FormikTextField
                        variant={'outlined'}
                        label={'description'}
                        name={'description'}
                        fullWidth
                        useHelperText={false}
                        rows={4}
                        multiline
                    />
                    <PreviewOrderItemEditDescButtons>
                        <ConfirmButton type="submit"><Check /></ConfirmButton>
                        <CancelButton onClick={handleCancel}><Clear /></CancelButton>
                    </PreviewOrderItemEditDescButtons>
                </PreviewOrderItemEditDescriptionContainer>
            </PreviewOrderItemEditDescriptionForm>
        </Formik>
    );
};

export default PreviewOrderItemEditDescription;