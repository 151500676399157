import React, { useCallback, useState } from 'react';
import {
    VehicleHeaderAddButton,
    VehiclesContainer,
    VehiclesHeader,
    VehiclesHeaderType,
    VehiclesTableContainer
} from './style';
import Translation from '../../components/Translation';
import { Translate } from '../../internationalization/translate';
import AddIcon from '@mui/icons-material/Add';
import Panel from '../../components/Panel';
import Table from 'components/Table';
import TableHeader from '../Users/TableHeader';
import { extendedDriverColumns } from '../Drivers/def';
import { VehiclesColumns } from './def';
import { useModal } from '../../hooks/useModal';
import DefineVehicleForm from './component/DefineVehicleForm';
import CustomSlide from '../../components/Transitions/Slide';

/**
 *
 * DEV COMMENT
 * @author Aleksandar Djordjevic
 * @date 29.03.2024
 *
 * TODO: Header move to separate component
 */

const Vehicles = ({ isVisible }: any) => {
    const [needRefetch, setNeedRefetch] = useState<number>(0);

    const { openModal: openDialogDefineVehicle, closeModal } = useModal({
        modal: DefineVehicleForm,
        isActionsButtonsVisible: false,
        isCloseAction: true
    });

    const handlerSetRefetch = useCallback(() => setNeedRefetch(new Date().getTime()), [setNeedRefetch]);

    const handlerOpenDialogDefineVehicle = useCallback(() => {
        openDialogDefineVehicle({
            setRefetch: handlerSetRefetch,
            closeModal
        });
    }, [closeModal, openDialogDefineVehicle, handlerSetRefetch]);

    const refetchDone = useCallback(() => setNeedRefetch(0), [setNeedRefetch]);

    return (
        <CustomSlide>
            <VehiclesContainer>
                <VehiclesHeader>
                    <Translation use={Translate.controlTableTitle} as={VehiclesHeaderType} />
                    <VehicleHeaderAddButton
                        onClick={handlerOpenDialogDefineVehicle}
                        startIcon={<AddIcon />}
                    >
                        <Translation use={Translate.vehicleAddButtonTitle} />
                    </VehicleHeaderAddButton>
                </VehiclesHeader>
                <VehiclesTableContainer>
                    <Panel>
                        <Table
                            columns={VehiclesColumns}
                            url={'/dispatcher/vehicles'}
                            queryKey={'vehicles'}
                            useHeader
                            extendedColumns={extendedDriverColumns}
                            needRefetch={needRefetch}
                            refetchDone={refetchDone}
                            preventFetch={isVisible}
                        >
                            <TableHeader
                                tableFilterOptions={[
                                    { value: true, label: 'Neaktivni', filterKey: 'inactive' },
                                    { value: true, label: 'Aktivini', filterKey: 'active' },
                                    { value: true, label: 'Nedostupni', filterKey: 'disabled' }
                                ]}
                            />
                        </Table>
                    </Panel>
                </VehiclesTableContainer>
            </VehiclesContainer>
        </CustomSlide>
    );
};


export default Vehicles;
