import {
    PreviewShiftRowContainerHeader,
    PreviewShiftRowItem,
    PreviewShiftRowStatus,
    PreviewShiftRowStatusText
} from './style';
import { Translate } from 'internationalization/translate';
import Translation from 'components/Translation';


const PreviewEndedShiftRowHeader = () => {

    return (
        <PreviewShiftRowContainerHeader>
            <Translation use={Translate.driver} as={PreviewShiftRowItem} />
            <Translation use={Translate.start} as={PreviewShiftRowStatusText} />
            <Translation use={Translate.expense} as={PreviewShiftRowStatusText} />
            <Translation use={Translate.earnings_} as={PreviewShiftRowStatusText} />
            <PreviewShiftRowStatus>
                <Translation use={Translate.difference} as={PreviewShiftRowStatusText} />
            </PreviewShiftRowStatus>
            <PreviewShiftRowStatusText />
        </PreviewShiftRowContainerHeader>
    );
};

export default PreviewEndedShiftRowHeader;