import axios from 'axios';
import { authRequest, authResponse } from './interceptors';

const axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
    timeout: 35000
});


/*export const refreshAuth = (failedRequest: any) => {
    const refreshToken = localStorage.getItem(CONTEXT_TYPES.REFRESH_TOKEN);
    return axiosClient.post('/refresh-token',{
        token: refreshToken
    }).then((tokenRefreshResponse: any) => {
        store.dispatch(setAuthData({
            ...tokenRefreshResponse.data,
            refreshToken
        }));
        failedRequest._retry = true;
        localStorage.setItem(CONTEXT_TYPES.ACCESS_TOKEN, tokenRefreshResponse.data.token);
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
        return Promise.resolve(axiosClient(failedRequest));
    })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            store.dispatch(clearUserData());
            localStorage.removeItem(CONTEXT_TYPES.ACCESS_TOKEN);
            localStorage.removeItem(CONTEXT_TYPES.REFRESH_TOKEN);
        });
};*/


axiosClient.interceptors.request.use(authRequest);
axiosClient.interceptors.response.use(...authResponse);
/*createAuthRefreshInterceptor(axiosClient, refreshAuth, {
    statusCodes: [401, 403],
});*/


export const transformRequest =  [function (data: any, headers: any) {
    if (headers['Content-Type'] && (headers['Content-Type'] as string).startsWith('multipart/form-data')) {
        const form = new FormData();
        for (const key in data) {
            const value = data[key];
            if(!value) continue;
            if (Array.isArray(value)) {
                const arrayKey = `${key}[]`;
                value.forEach((v) => {
                    form.append(arrayKey, v);
                });
            } else{
                form.append(key, value);
            }
        }
        return form;
    }
    return { data, headers };
}];

export default axiosClient;
