import styled from 'styled-components';
import { Flex } from '../../../../../../../assets/style';
import ButtonIcon from '../../../../../../../components/button/ButtonIcon';


export const MapPinDropContainer = styled(Flex)`
    width: 90vw;
    height: 80vh;
    padding: 10px;
`;

export const DefineMapPinContainer = styled(Flex)`
    justify-content: center;
    padding-top: 10px;
`;

export const DefineMapPinDrop = styled(ButtonIcon)`
      &&& {
          color: white;
          border: 1px solid white;
      }
`;
