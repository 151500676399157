import { useMutation } from '@tanstack/react-query';
import axiosClient from '../../../config/axios';
import { createUrl } from '../../../utils/createUrl';

const useRemoveNotificationMutation  = () => {
    const mutation = useMutation({
        mutationFn: async (id: number) => {
            const response = await axiosClient.delete(createUrl({
                url: `/dispatcher/notifications/${id}`,
                version: 'v1',
            }));
            return response.data;
        }
    });
    return mutation;
};

export default useRemoveNotificationMutation;