import {AppToolBarRightPart} from 'components/appbar/style';
import UserAvatar from 'components/appbar/appbarRight/UserAvatar';
import ApprovalRequests from './ApprovalRequests';
import Notifications from './Notifications';


const AppbarRight = ()=> {


    return (
        <AppToolBarRightPart>
            <Notifications />
            <ApprovalRequests />
            <UserAvatar/>
        </AppToolBarRightPart>
    );
};

export default AppbarRight;
