import React, { useMemo } from 'react';
import {
    PreviewOrderColumn,
    PreviewOrderContainer,
    PreviewOrderCustomerContainer,
    PreviewOrderHeader,
    PreviewOrderIsAbortedText, PreviewOrderIsScheduledText,
    PreviewOrderItemsContainer,
    PreviewOrderRow, PreviewOrderScheduledText
} from './style';
import useModalFormData from '../../../../../hooks/useModalFormData';
import { Translate } from '../../../../../internationalization/translate';
import { useTranslation } from 'react-i18next';
import useGetOrderQuery from '../../../../../core/api/hooks/query/orders/useGetOrderQuery';
import { formatCurrency } from '../../../../../utils';
import PreviewOrderItem from './PreviewOrderItem/PreviewOrderItem';
import Translation from '../../../../../components/Translation';
import { add, round } from 'lodash';
import moment from 'moment';
import ConditionalRendering from '../../../../../components/Utils/ConditionalRendering';
import RenderLabelValue from '../../../../../components/Utils/RenderLabelValue';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import { ORDER_STATUS } from '../../../d';

const PreviewOrder = ({
                          id,
                          order
                      }: any) => {
    const {t} = useTranslation();

    const {data} = useGetOrderQuery(id);
    
    const [driver, orderDeliveryAmount, orderAmount, sumAmount, isAborted, abortTime, orderTime, isScheduled] = useMemo(()=> {
        return [
            data?.driver ? `${data?.driver?.firstName} ${data?.driver?.lastName}` : '',
            data?.orderDeliveryAmount || '',
            data?.orderAmount || round((data?.items || []).filter((x: any)=> x.isCompleted)?.reduce((acc: number, x: any) => add(acc, x.amount || 0),0), 2) || 0,
            (()=> {
                const orderAmount =  data?.orderAmount || round((data?.items || []).filter((x: any)=> x.isCompleted)?.reduce((acc: number, x: any) => add(acc, x.amount || 0),0), 2);
                return round(add(orderAmount, Number(data?.orderDeliveryAmount) || 0), 2);
            })(),
            !!data?.isAborted,
            data?.abortTime ? moment(data?.abortTime).format( 'DD.MM.YYYY HH:mm') : '',
            data?.orderTime ? moment(`${data?.orderDate} ${data?.orderTime}`).format( 'DD.MM.YYYY HH:mm') : '',
            data?.status === ORDER_STATUS.SCHEDULED
        ];
    },[data]);

    useModalFormData({
        modalTitle: t(Translate.orderPreviewDialogTitle, {num: order?.dailyNumber})
    });

    return (
        <PreviewOrderContainer>
            <ConditionalRendering condition={isScheduled}>
                <Translation use={Translate.scheduled} as={PreviewOrderScheduledText} />
            </ConditionalRendering>
            <PreviewOrderHeader>
                <PreviewOrderRow justify={'space-between'}>
                    <PreviewOrderColumn flex={2}>
                        <PreviewOrderCustomerContainer label={Translate.defineOrderCustomerNameLabel} value={data?.customerName} />
                        <PreviewOrderCustomerContainer label={Translate.defineOrderCustomerAddressLabel} value={data?.customerAddress} flex={2} />
                        <PreviewOrderCustomerContainer label={Translate.defineOrderCustomerBuildingNumberLabel} value={data?.customerBuildingNumber} flex={2} />
                        <PreviewOrderCustomerContainer label={Translate.defineOrderCustomerPhoneLabel} value={data?.customerPhone} />
                        <PreviewOrderCustomerContainer label={Translate.defineOrderFooterDriver} value={driver} placeholder={'######'}/>
                        <ConditionalRendering condition={isScheduled}>
                            <PreviewOrderCustomerContainer label={Translate.orderPreviewOrderTime} value={orderTime} />
                        </ConditionalRendering>
                        <ConditionalRendering condition={isAborted}>
                            <PreviewOrderCustomerContainer label={Translate.orderPreviewOrderAbortTimeLabel} value={abortTime} />
                        </ConditionalRendering>
                    </PreviewOrderColumn>
                    <PreviewOrderColumn>
                        <PreviewOrderCustomerContainer label={Translate.orderPreviewOrderAmount} value={orderAmount} format={formatCurrency} align={'flex-end'} />
                        <PreviewOrderCustomerContainer label={Translate.orderPreviewDeliveryAmount} value={orderDeliveryAmount} format={formatCurrency} align={'flex-end'} />
                        <PreviewOrderCustomerContainer label={Translate.orderPreviewTotalAmount} value={sumAmount} format={formatCurrency} align={'flex-end'} />
                    </PreviewOrderColumn>
                </PreviewOrderRow>
                <ConditionalRendering condition={isScheduled}>
                    <PreviewOrderIsScheduledText title={t(Translate.scheduled)}>
                        <DepartureBoardIcon />
                    </PreviewOrderIsScheduledText>
                </ConditionalRendering>
                <ConditionalRendering condition={isAborted}>
                    <PreviewOrderColumn flex={0}>
                        <RenderLabelValue
                            label={Translate.orderPreviewOrderAbortNoteLabel}
                            value={data?.abortNote || ''}
                        />
                    </PreviewOrderColumn>
                    <PreviewOrderIsAbortedText title={t(Translate.aborted)}>
                        <DangerousIcon />
                    </PreviewOrderIsAbortedText>
                </ConditionalRendering>
            </PreviewOrderHeader>
            <PreviewOrderItemsContainer>
                <PreviewOrderRow>
                    <Translation use={Translate.orderPreviewItemsHeader} as={'div'}/>
                </PreviewOrderRow>
                {data?.items?.length ? data?.items.map((item: any, index: number) => <PreviewOrderItem  item={item} index={index+1} key={index} status={data.status} orderId={data.id}/>) : null}
            </PreviewOrderItemsContainer>
        </PreviewOrderContainer>
    );
};

export default PreviewOrder;