import { ClearItemButton, ShiftItemContainer } from './style';
import { Translate } from '../../../../../../../../internationalization/translate';
import { useCallback, useContext, useMemo } from 'react';
import ShiftSchedulingForDayContext from '../../../context/context';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector } from 'react-redux';
import { selectDrivers, selectVehicles } from '../../../../../../../../store/app/appSlice';
import FormikSelectField from '../../../../../../../../components/Formik/FormikSelectField';
import FormikInputCurrency from '../../../../../../../../components/Formik/FormikInputCurrency';
import ConditionalRendering from '../../../../../../../../components/Utils/ConditionalRendering';
import ButtonActionShiftStarted from '../../../../DefineUpdateShift/components/ButtonActionShiftStarted';
import { useFormikContext } from 'formik';
import { SHIFT_SCHEDULING_STATUS } from '../../../../../../view/SchedulingBoard/d';

const ShiftItem = ({ item }: any) => {

    const drivers = useSelector(selectDrivers);
    const vehicles = useSelector(selectVehicles);
    const { removeItemByShift } = useContext(ShiftSchedulingForDayContext);
    const { unregisterField, setFieldValue } = useFormikContext();

    const [isUpdate, isEnded] = useMemo(()=> [
        item.isUpdate,
        item.status === SHIFT_SCHEDULING_STATUS.END || item.status === SHIFT_SCHEDULING_STATUS.DISP_END
    ], [item]);
    
    const handlerRemoveShiftItem = useCallback(() => {
        if (!item?.id || item.isUpdate) return;
        removeItemByShift(item.id);
        unregisterField(`${item.shiftId}_vehicleId_${item.id}`);
        unregisterField(`${item.shiftId}_driverId_${item.id}`);
        unregisterField(`${item.shiftId}_amount_${item.id}`);
        setFieldValue(`${item.shiftId}_vehicleId_${item.id}`, undefined as any);
        setFieldValue(`${item.shiftId}_driverId_${item.id}`, undefined  as any);
        setFieldValue(`${item.shiftId}_amount_${item.id}`, undefined  as any);
    }, [removeItemByShift, item, unregisterField, setFieldValue]);

    const vehiclesOptions = useMemo(() => vehicles.map((x: any) => ({
        value: x.id,
        label: x.name
    })), [vehicles]);

    const driversOptions = useMemo(() => drivers.map((x: any) => ({
        value: x.id,
        label: `${x.fullName}`
    })), [drivers]);

    return (
        <ShiftItemContainer>
            <ConditionalRendering condition={!isUpdate}>
                <ClearItemButton onClick={handlerRemoveShiftItem}>
                    <ClearIcon />
                </ClearItemButton>
            </ConditionalRendering>
            <ConditionalRendering condition={isUpdate && !isEnded}>
                <ButtonActionShiftStarted item={item}/>
            </ConditionalRendering>
            <FormikSelectField
                name={`${item.shiftId}_vehicleId_${item.id}`}
                variant={'outlined'}
                label={Translate.vehicle}
                options={vehiclesOptions}
                disabled={isEnded}
            />
            <FormikSelectField
                name={`${item.shiftId}_driverId_${item.id}`}
                variant={'outlined'}
                label={Translate.driver}
                options={driversOptions}
                disabled={item.isUpdate || isEnded}
            />
            <FormikInputCurrency
                name={`${item.shiftId}_amount_${item.id}`}
                variant={'outlined'}
                label={Translate.amount}
                disabled={item.isUpdate || isEnded}
            />
        </ShiftItemContainer>
    );
};

export default ShiftItem;