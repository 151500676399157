import React, { useEffect, useMemo, useState } from 'react';
import {
    RestaurantLocationMapContainer
} from '../../../../../../Restaurants/components/DefineRestaurantForm/RestaurantLocationForm/style';
import { Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import { MapPinDropContainer } from './style';
import { getGeocode } from 'use-places-autocomplete';


const Recenter = ({ position: _position, changeLocation }: any) => {
    const [position, setPosition] = useState<any>(null);


    const map = useMapEvents({
        dblclick(e: any) {
            setPosition(e.latlng);
            changeLocation(e);
        },
        zoomanim() {
            setPosition(_position);
        }
    });

    useEffect(() => {
        if (!position) return;
        setPosition(position);
        map.setView([position.lat, position.lng]);
    }, [position]);

    useEffect(() => {
        if (!_position) return;
        setPosition(_position);
    }, [_position]);


    return (<>
            {
                position !== null ? (
                    <Marker position={position}>
                        <Popup>
                            A pretty CSS3 popup. <br /> Easily customizable.
                        </Popup>
                    </Marker>
                ) : null
            }
        </>
    );
};


const MapPinDrop = ({ dataEntry, changeData }: any) => {
    const [mapOptions, setMapOptions] = React.useState({
        position: dataEntry?.locationMap ? { lat: dataEntry?.locationMap?.lat, lng: dataEntry?.locationMap?.lon } : {
            lat: 43.58226,
            lng: 21.32918
        },
        zoom: 17
    } as any);

    const [position, zoom] = useMemo(() => ([
        {
            lat: mapOptions?.position?.lat || 43.58226,
            lng: mapOptions?.position?.lng || 21.32918
        },
        mapOptions.zoom
    ]), [mapOptions]);

    const changeLocation = async ({ latlng }: any) => {
        const results = await getGeocode({
            location: latlng,
            language: 'sr-Latn'
        });
        const address = results?.[0];
        const obj = {
            ...results?.[0],
            ...latlng,
            description: address?.formatted_address
        };
        await changeData(address?.formatted_address, obj);
        setMapOptions((v: any) => ({
            ...v,
            position: latlng
        }));
    };

    React.useEffect(() => {
        if (!dataEntry) return;
        setMapOptions({
            zoom: 17,
            position: { lat: dataEntry?.locationMap?.lat, lng: dataEntry?.locationMap?.lon }
        });
    }, [dataEntry]);

    return (
        <MapPinDropContainer>
            <RestaurantLocationMapContainer center={position as any} zoom={zoom} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Recenter position={position} changeLocation={changeLocation} />
            </RestaurantLocationMapContainer>
        </MapPinDropContainer>
    );
};

export default MapPinDrop;